import shuffle from 'lodash.shuffle';
import { isAttempted } from 'store/questions/selectors';
import { QuestionsState } from 'store/questions/types';

export const shuffleAnswerOptions = (answerOptions: any[]): any[] => {
  if (answerOptions.length <= 1) {
    return answerOptions;
  }

  let shuffledArray = shuffle(answerOptions);
  let iterationCount = 0;

  const MAX_RETRIES = 10;

  while (
    JSON.stringify(shuffledArray) === JSON.stringify(answerOptions) &&
    iterationCount < MAX_RETRIES
  ) {
    iterationCount++;
    shuffledArray = shuffle(shuffledArray);
  }

  return shuffledArray;
};

export const getAttemptedCount = (questions: Array<QuestionsState>): number =>
  questions.filter((question: any) => isAttempted(question)).length;

export default shuffleAnswerOptions;
