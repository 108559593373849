import initialState from 'store/initialState';
import { ActionTypes, UserActionTypes } from './types';

export default function loginReducer(state = initialState.user, action: UserActionTypes) {
  switch (action.type) {
    case ActionTypes.USER_AUTHENTICATED:
    case ActionTypes.USER_UPDATED:
      return { ...state, ...action.payload };
    case ActionTypes.USER_UNAUTHENTICATED:
      return { ...state, ...initialState.user };
    case ActionTypes.USER_AUTHENTICATION_SKIPPED:
      return { ...state, ...initialState.user, ...{ authorizationSkipped: true } };
    case ActionTypes.USER_AUTHENTICATION_NOT_SKIPPED:
      return { ...state, ...initialState.user, ...{ authorizationSkipped: false } };
    default:
      return state;
  }
}
