import styled, { css } from 'styled-components';
import { transparentize } from 'utils/color';
import media from 'components/mixins/media';
import * as typography from 'components/mixins/typography';
import { pulse } from 'components/mixins/interactivity';
import Icon from 'components/common/Icon';
import { centerAbsoluteElement } from 'components/mixins/placement';
import { defaultBoxShadowColor } from 'constants/common';

const pulseAnimationColors = {
  start: transparentize(defaultBoxShadowColor, 0.05),
  middle: transparentize(defaultBoxShadowColor, 0.03),
  end: transparentize(defaultBoxShadowColor, 0.01)
};

export const PlusIcon = styled(Icon)`
  position: absolute;
  ${centerAbsoluteElement};
  text-indent: 0;
  color: ${props => props.theme.colors.mainColor};
`;

type DropdownButtonProps = {
  isMatching: boolean;
  isMatchingOption: boolean;
};
export const DropdownButton = styled.button<DropdownButtonProps>`
  &.dropdown-button {
    vertical-align: top;
    position: relative;
    display: inline-block;
    max-width: 280px;
    min-width: 210px;
    min-height: 40px;
    margin: 0 8px;
    padding: 0 30px 0 14px;
    border: none;
    border-radius: 2px;
    background-color: ${props => props.theme.colors.contentBodyColor};
    box-shadow: 0 2px 10px 0 ${transparentize(defaultBoxShadowColor, 0.1)};
    color: ${props => props.theme.colors.textColor};
    ${typography.secondaryFontText};
    text-align: left;
    outline: none;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    white-space: nowrap;
    transition: all 0.1s ease-out;
    cursor: pointer;

    &::-moz-focus-inner {
      outline: none;
      border: 0;
    }

    .dropdown-arrow {
      content: '';
      position: absolute;
      top: 50%;
      right: 15px;
      margin-top: -4px;
      width: 6px;
      height: 6px;
      color: ${props => props.theme.colors.textColor};
      border-style: solid;
      border-width: 2px 2px 0 0;
      vertical-align: top;
      transform: rotate(${props => (props.theme.isRtl ? '225' : '135')}deg);
      transition: transform 0.1s ease-out;
      &.is-open {
        margin-top: -3px;
        transform: rotate(${props => (props.theme.isRtl ? '45' : '-45')}deg);
      }
    }

    &.pulse {
      ${pulse(pulseAnimationColors)}
    }

    &.empty {
      color: ${props =>
        transparentize(props.theme.colors.textColor, props.theme.themeUtils.getOpacity(true, 0.5))};
    }

    &:hover {
      box-shadow: 0 4px 8px 0 ${transparentize(defaultBoxShadowColor, 0.1)};
    }
    &:focus {
      box-shadow: 0 1px 0 0 ${transparentize(defaultBoxShadowColor, 0.1)},
        inset 0 1px 3px 0 ${transparentize(defaultBoxShadowColor, 0.1)};
      outline: 1px solid black;
    }

    ${props =>
      props.isMatching
        ? css`
            &.dropdown-button {
              overflow: visible;
              max-width: auto;
              min-width: auto;
              max-height: none;
              min-height: 40px;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              padding: 0;
              background: ${props.theme.colors.contentBodyColor};
              box-shadow: none;
              text-indent: -999999px;
              z-index: 1;

              &:before {
                content: '';
                display: block;
                position: absolute;
                top: -3px;
                left: -3px;
                height: 40px;
                width: 40px;
                border-radius: 50%;
                transition: 0.3s;
                border: 3px solid ${transparentize(props.theme.colors.contentBodyColor, 0.5)};
              }

              &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: transparent;
                transition: 0.3s;
                border-radius: 50%;
                box-shadow: 0 2px 10px 0 ${transparentize(defaultBoxShadowColor, 0.1)};
              }

              &:hover {
                background: ${transparentize(props.theme.colors.contentBodyColor, 0.9)};

                &:after {
                  background: ${transparentize(props.theme.colors.mainColor, 0.25)};
                }

                &:before {
                  top: -11px;
                  left: -11px;
                  border: 11px solid ${transparentize(props.theme.colors.contentBodyColor, 0.2)};
                }
              }

              &:focus {
                background: ${transparentize(props.theme.colors.contentBodyColor, 0.9)};

                &:after {
                  background: ${transparentize(props.theme.colors.mainColor, 0.25)};
                }
              }

              &[aria-expanded='true'] {
                background: ${props.theme.colors.mainColor};
                vertical-align: top;

                &:before {
                  display: none;
                }

                &:after {
                  background: transparent;
                }

                ${PlusIcon} {
                  color: ${props.theme.colors.contentBodyColor};
                }
              }

              .dropdown-arrow {
                display: none;
                top: 50%;
                left: 50%;
                margin-top: -3px;
                margin-left: -3px;
                color: ${props.theme.colors.mainColor};

                &.is-open {
                  top: 50%;
                  margin-top: -2px;
                }
              }

              & + .chosen-value {
                background: ${props.theme.colors.contentBodyColor};
                position: absolute;
                top: 0;
                left: 45px;
                padding: 4px 30px 3px 16px;
                line-height: 32px;
                border-radius: 2px;
                ${typography.secondaryFontText};
                white-space: nowrap;
                max-width: 340px;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              ${media.xlarge`
                width: 36px;
                height: 36px;
                min-height: 36px;

                &:before {
                  height: 36px;
                  width: 36px;
                }
            `}

              ${media.xsmall`
              width: 30px;
              height: 30px;
              min-height: 30px;

              &:before {
                height: 30px;
                width: 30px;
              }
              ${css`
                 {
                  ${PlusIcon} {
                    font-size: 8px;
                  }
                }
              `}
              & + .chosen-value {
                left: 39px;
              }
            `};
            }
          `
        : css``};

    ${props =>
      props.isMatching && props.isMatchingOption
        ? css`
            &.dropdown-button {
              ${typography.secondaryFontText};
              width: 100%;
              max-width: 100%;
              height: auto;
              max-height: 100%;
              margin: 0;
              padding: 3px 15px 5px;
              border-radius: 0;
              background-color: transparent;
              box-shadow: none;
              overflow: hidden;
              text-overflow: none;
              white-space: normal;
              word-break: break-word;
              text-indent: 0;
              transition: none;
              animation: none;

              &.empty-matching-option {
                color: ${transparentize(
                  props.theme.colors.textColor,
                  props.theme.themeUtils.getOpacity(true, 0.4)
                )};
                text-indent: 0;

                &:hover,
                &:focus {
                  color: ${transparentize(
                    props.theme.colors.textColor,
                    props.theme.themeUtils.getOpacity(true, 0.7)
                  )};
                  background: ${transparentize(props.theme.colors.mainColor, 0.25)};
                }
              }

              &.dropdown-button {
                &[aria-expanded='true'] {
                  background: ${transparentize(props.theme.colors.textColor, 0.02)};
                  vertical-align: top;

                  &:before,
                  &:after {
                    display: none;
                  }
                }

                &.empty {
                  &:hover {
                    color: ${props.theme.colors.textColor};
                    box-shadow: none;
                  }
                }

                &:hover,
                &:focus {
                  background: ${transparentize(props.theme.colors.mainColor, 0.25)};
                  box-shadow: none;

                  &:before,
                  &:after {
                    display: none;
                  }
                }
              }

              &:before,
              &:after {
                width: 100%;
                height: 100%;
                border-radius: 0;
                border-width: 0;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
              }

              ${media.xlarge`
                height: auto;
              `};

              ${media.xsmall`
                width: 100%;
                height: auto;

                &:before {
                  width: 100%;
                  height: auto;
                }

                & + .chosen-value {
                  left: 0;
                }
              `}

              & + .chosen-value {
                display: none;
                width: 100%;
                background: transparent;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                color: ${transparentize(
                  props.theme.colors.textColor,
                  props.theme.themeUtils.getOpacity(true, 0.5)
                )};
                text-indent: 0;
                font-size: 14px;
                line-height: 32px;
                word-break: break-word;
              }
            }
          `
        : css``}
  }
`;

export default DropdownButton;
