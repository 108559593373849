
export enum ActionTypes {
    TOGGLE_CHECK = 'TOGGLE_CHECK',
    INIT_CHECKLIST = 'INIT_CHECKLIST'
}

export type ChecklistState = {
    [key: string]: {
        isChecked: boolean;
    }
}

type ToggleCheck = {
    [key: string]: any,
    type: ActionTypes.TOGGLE_CHECK,
    payload: {
        [key: string]: {
            isChecked: boolean
        }
    }
}

type InitChecklist = {
    [key: string]: any,
    type: ActionTypes.INIT_CHECKLIST,
    payload: {
        [key: string]: {
            isChecked: boolean
        }
    }
}

export type ChecklistActionTypes = ToggleCheck | InitChecklist;
