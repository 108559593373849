import {
  MULTIPLE_CHOICE,
  SINGLE_CHOICE,
  SINGLE_CHOICE_IMAGE,
  STATEMENT_CHOICE,
  OPEN_QUESTION,
  RANKING_TEXT,
  SCENARIO,
  FILL_IN_THE_BLANK,
  DRAG_AND_DROP_TEXT,
  TEXT_MATCHING,
  HOTSPOT
} from 'constants/questionTypes';
import { FONT_SIZE_ADJUSTMENT_OPTIONS } from 'constants/accessibility';
import { MEDIA_TABLET } from 'constants/screenResolutions';
import { isLowerResolution } from 'utils/window';
import { ProgressStatus } from 'constants/progressStatus';
import { TimerStatus } from 'constants/timer';
import { WebSocketStatus } from 'store/webSocket/types';
import { RootAppState } from './types';
import { LAYOUT } from 'constants/review';

const state: RootAppState = {
  accessibility: {
    fontSize: FONT_SIZE_ADJUSTMENT_OPTIONS.DEFAULT,
    isContrastTheme: false,
    isDyslexiaFont: false,
    isReadableFont: false,
    isSeizureSafe: false
  },
  app: {
    isLoading: true,
    isLoaded: false,
    isLoadingFailed: false,
    isLowResolution: false,
    isOffline: false,
    isLrsMisconfigurationError: false,
    isError: false
  },
  course: {
    sections: [],
    title: '',
    id: '',
    templateId: '',
    createdBy: '',
    createdOn: new Date(),
    hasIntroductionContent: false,
    isLoadingFailed: false,
    attemptId: null,
    started: false,
    hasBeenContinued: false,
    result: 0,
    isCompleted: false,
    isCertificateDownloaded: false,
    score: 0,
    status: ProgressStatus.IN_PROGRESS,
    passedAfterwords: [],
    failedAfterwords: [],
    isAttemptSubmitted: false,
    courseAttempt: 0,
    isLaunched: false,
    isProgressRestoreFailed: false,
    isCourseLaunchFailed: false,
    isProgressRestoreFetched: false,
    timeSpent: {
      accumulatedValueInMilliseconds: 0,
      incrementStartedAt: new Date()
    },
    isRandomizedOptionsLoaded: false,
    isCourseFinished: false
  },
  timer: {
    status: TimerStatus.NOT_STARTED,
    elapsed: 0
  },
  modules: {
    xapi: {
      isInitialized: false,
      isLrsTrackingOn: false,
      isNpsTrackingOn: false,
      isLxpTrackingOn: false
    },
    scorm: {
      isInitialized: false
    },
    progressStorage: {
      isProgressStorageOn: false
    },
    review: {
      isReviewModeEnabled: false,
      reviewApiUrl: '',
      layout: LAYOUT.LOGIN_WITH_EMAIL
    },
    lti: {
      isInitialized: false
    }
  },
  sections: {},
  questions: {},
  contents: {},
  settings: {
    isLoadingFailed: false,
    template: {},
    publish: {},
    manifest: {},
    translations: {},
    publishMode: {},
    materialType: ''
  },
  navigation: {
    chain: [],
    prevUrl: null,
    elementIdInViewport: ''
  },
  treeOfContent: {
    isExpanded: true,
    isSubmitButtonVisible: false
  },
  popup: {
    isExpanded: false,
    isAccessibilityExpanded: null,
    settings: {}
  },
  loader: {
    isLoaded: false
  },
  user: {
    account: null,
    email: '',
    name: '',
    shortTermAccess: true,
    isAuthenticatedReviewer: false,
    isRegisteredReviewer: false,
    authorizationSkipped: false
  },
  resource: {
    id: '',
    resourceType: '',
    title: '',
    createdBy: '',
    createdOn: new Date(),
    contentId: '',
    resourceContents: []
  },
  checklist: {},
  comments: [],
  webSocket: { status: WebSocketStatus.DISABLED, isAuthenticated: false }
};

export default state;

export function initialize() {
  if (isLowerResolution(MEDIA_TABLET)) {
    state.treeOfContent.isExpanded = false;
  }
}

export function getInitialQuestionResponse(questionType: string) {
  switch (questionType) {
    case SINGLE_CHOICE:
    case SINGLE_CHOICE_IMAGE:
    case MULTIPLE_CHOICE:
    case STATEMENT_CHOICE:
    case OPEN_QUESTION:
    case RANKING_TEXT:
    case SCENARIO:
    case FILL_IN_THE_BLANK:
    case DRAG_AND_DROP_TEXT:
    case TEXT_MATCHING:
    case HOTSPOT:
      return [];
    default:
      return null;
  }
}
