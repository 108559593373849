import { getQuestion } from 'store/questions/selectors';
import { RootAppState } from 'store/types';

export const getEmbedCode = (state: RootAppState, questionId: string) => {
  const question = getQuestion(state, questionId);
  return question.embedCode;
};

export const getProjectId = (state: RootAppState, questionId: string) => {
  const question = getQuestion(state, questionId);
  return question.projectId;
};

export const getUserAnswers = (state: RootAppState, questionId: string) => {
  let question = getQuestion(state, questionId);
  const responseAnswer = question.response !== null ? question.response : '';
  return {
    score: !responseAnswer ? '' : responseAnswer.score,
    isFinished: !responseAnswer ? '' : responseAnswer.isFinished
  };
};

export const getScore = (state: RootAppState, question: any) => {
  if (question.response.score >= question.masteryScore && question.response.isFinished) {
    return 100;
  }
  return 0;
};

export const isAttempted = (question: any) => !!question.response;
