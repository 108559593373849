export enum ActionTypes {
  OPEN_POPUP = 'OPEN_POPUP',
  CLOSE_POPUP = 'CLOSE_POPUP',
  OPEN_ACCESSIBILITY_USER_MENU_SECTION = 'OPEN_ACCESSIBILITY_USER_MENU_SECTION',
  CLOSE_ACCESSIBILITY_USER_MENU_SECTION = 'CLOSE_ACCESSIBILITY_USER_MENU_SECTION'
}

type OpenPopup = {
  type: ActionTypes.OPEN_POPUP;
  payload: {
    settings: { [key: string]: any };
  };
};

type ClosePopup = {
  type: ActionTypes.CLOSE_POPUP;
};

type OpenAccessibilityAppMenuSection = {
  type: ActionTypes.OPEN_ACCESSIBILITY_USER_MENU_SECTION;
};

type CloseAccessibilityAppMenuSection = {
  type: ActionTypes.CLOSE_ACCESSIBILITY_USER_MENU_SECTION;
};

export type PopupActionTypes =
  | OpenPopup
  | ClosePopup
  | OpenAccessibilityAppMenuSection
  | CloseAccessibilityAppMenuSection;
