import styled, { css } from 'styled-components';
import media from 'components/mixins/media';
import { headerBackground } from 'components/mixins/backgrounds';
import { transparentize } from 'utils/color';
import { ToastContainer } from 'react-toastify';
import { Icon } from 'components/common';
import { FONT_SIZE, PAGE_NAME } from 'constants/components';
import { fontSizeAdjusted } from 'components/mixins/typography';

export const fullSizeAbsoluteBlock = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const LayoutContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  ${media.small`
    position: fixed;
    width: 100vw;
    height: 100%;
  `};

  #accessibility-description > div {
    align-items: center;
    overflow: hidden;
  }
`;

export const LayoutWrapper = styled.div`
  position: relative;
  height: 100%;
  width: inherit;
  display: flex;
`;

type BackgroundContainerProps = {
  background: any;
};
export const BackgroundContainer = styled.div<BackgroundContainerProps>`
  position: relative;
  flex: 0 0 50%;
  height: 100vh;
  ${props => headerBackground(props.background)};
  ${media.tablet`
    flex: 1 1 100%;
  `};
`;

export const PageSidebarChild = styled.div.attrs({
  'data-sidebar-child': true
})`
  ${fullSizeAbsoluteBlock};
  ${media.tablet`
    position: relative;
    width: 100%;
    height: auto;
  `};
`;

export const PageContentChild = styled.div.attrs({
  'data-content-child': true
})`
  ${fullSizeAbsoluteBlock};
  ${media.tablet`
    position: relative;
    width: 100%;
    height: auto;
  `};
`;

export const PageContainer = styled.div`
  ${fullSizeAbsoluteBlock};
  overflow: hidden;
`;

export const PageSidebarContainer = styled.aside.attrs({ 'data-sidebar': true })``;

export const PageContentContainer = styled.div.attrs({
  'data-content': true
})``;

type BasicStylesSidebarProps = {
  background?: any;
};
export const BasicStylesSidebar = styled.aside.attrs({ 'data-sidebar': true })<
  BasicStylesSidebarProps
>`
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow-y: auto;
  background-color: ${props =>
    props.background.header.coverOverlay.enabled
      ? transparentize(
          props.background.header.coverOverlay.color,
          props.background.header.coverOverlay.opacity
        )
      : null};
  [data-type*='flipCards'].eg-content-editor .flip-button {
    color: ${(props: any) => props.theme.colors.coverBgOverlayTextColor};
  }
`;

export const AccessibilityInvertedIcon = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  background-color: ${props => props.theme.colors.mainColor};
  padding: 0;
  border-radius: 100%;
`;
export const ToastCloseIcon = styled(Icon)`
  height: ${fontSizeAdjusted(10, { property: 'height' })};
  opacity: ${props => props.theme.themeUtils.getOpacity(true, 0.5, { contrastValue: 1 })};
`;
export const AccessibilityToast = styled(ToastContainer).attrs({
  toastClassName: 'accessibility-toast'
})`
  right: 16px;
  bottom: 20px;
  ${fontSizeAdjusted(FONT_SIZE.COVER_PAGE.SYSTEM.H2, { page: PAGE_NAME.COVER_PAGE })};
  width: max-content;
  padding: 0;

  .accessibility-toast {
    min-height: 63px;
    min-width: 308px;
    max-width: 400px;
    width: max-content;
    margin: 0;
    padding: 13px 10px 14px 24px;
    border-radius: 0px;
    line-height: 1.29;
    font-family: ${props => props.theme.fonts.mainFont.fontFamily};
    color: ${props => props.theme.colors.textColor};
  }

  .Toastify__toast-body {
    padding: 0px 28px 0px 0px;
  }

  .Toastify--animate-icon {
    margin-right: 12px;
    width: auto;
  }

  @keyframes Toastify__slideInUp {
    0% {
      transform: translate(0, -10px);
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes Toastify__slideOutDown {
    0% {
      transform: translate(0, 0);
      opacity: 1;
    }
    100% {
      transform: translate(0, -10px);
      opacity: 0;
    }
  }

  .Toastify__slide-enter--bottom-right {
    animation: Toastify__slideInUp 0.5s ease-out forwards;
  }

  .Toastify__slide-exit--bottom-right {
    animation: Toastify__slideOutDown 0.5s ease-out forwards;
  }

  .Toastify__slide-enter--bottom-left {
    animation: Toastify__slideInUp 0.5s ease-out forwards;
  }

  .Toastify__slide-exit--bottom-left {
    animation: Toastify__slideOutDown 0.5s ease-out forwards;
  }
`;
