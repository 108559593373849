/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import * as React from 'react';
import Downshift from 'downshift';
import { withTheme } from 'styled-components';
import { Helper } from './DropdownInput.styled';
import { DropdownInputPopover } from './popover/DropdownInputPopover';
import { DropdownInputButton } from './button/DropdownInputButton';

type DropdownInputProps = {
  data: any[];
  isMatching?: boolean;
  isMatchingOption?: boolean;
  onChange(e?: any): void;
  name: string;
  placeholder: string;
  answeredPlaceholder: string;
  matchingAnswer?: string;
  value: string;
  disabled?: boolean;
  theme?: { [key: string]: any };
  buttonAnimation?: string;
  popoverPosition: string;
  children?: any;
  questionType?: string;
};

type DropdownInputState = {
  selectedAnswer: any;
  isPopoverOpen: boolean;
}
class DropdownInput extends React.Component<DropdownInputProps, DropdownInputState> {
  constructor(props: DropdownInputProps) {
    super(props);
    this.state = {
      selectedAnswer: this.props.value,
      isPopoverOpen: false
    }
  }

  onOptionSelect = (selectedAnswer: any) => {
    if (selectedAnswer) {
      this.setState({ selectedAnswer: selectedAnswer.value });
    }
  };

  componentDidUpdate(prevProps: DropdownInputProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ selectedAnswer: this.props.value });
    }
  }

  onStateChange = (state: any) => {
    if (typeof state.isOpen === 'undefined') {
      return;
    }
    this.setState({ isPopoverOpen: state.isOpen });
  };

  render() {
    const {
      placeholder,
      answeredPlaceholder,
      onChange,
      disabled = false,
      popoverPosition,
      buttonAnimation = '',
      isMatching = false,
      isMatchingOption = false,
      matchingAnswer = ''
    } = this.props;
    let { selectedAnswer, isPopoverOpen } = this.state;

    if (matchingAnswer) {
      selectedAnswer = matchingAnswer;
    }

    return (
      <Helper isMatching={isMatching} isMatchingOption={isMatchingOption}>
        <Downshift
          onChange={onChange}
          selectedItem={selectedAnswer}
          onSelect={this.onOptionSelect}
          itemToString={data => (data ? data.value : '')}
          onStateChange={this.onStateChange}
        >
          {({
            isOpen,
            getToggleButtonProps,
            getItemProps,
            highlightedIndex,
            getLabelProps,
            getMenuProps,
            getRootProps,
            setHighlightedIndex
          }) => {
            const renderChosenValue = () => <div className="chosen-value">{matchingAnswer}</div>;

            return (
              <div title={selectedAnswer || placeholder} className={'dropdown'}>
                <DropdownInputPopover
                  data={this.props.data}
                  theme={this.props.theme || {}}
                  isPopoverOpen={isPopoverOpen}
                  popoverPosition={popoverPosition}
                  getMenuProps={getMenuProps}
                  getItemProps={getItemProps}
                  selectedAnswer={selectedAnswer}
                  highlightedIndex={highlightedIndex}
                  isMatching={isMatching}
                  setHighlightedIndex={setHighlightedIndex}
                  questionType={this.props.questionType}
                >
                  <DropdownInputButton
                    placeholder={placeholder}
                    answeredPlaceholder={answeredPlaceholder}
                    selectedAnswer={selectedAnswer}
                    matchingAnswer={matchingAnswer}
                    disabled={disabled}
                    getToggleButtonProps={getToggleButtonProps}
                    getLabelProps={getLabelProps}
                    getRootProps={getRootProps}
                    isOpen={isOpen}
                    isMatching={isMatching}
                    isMatchingOption={isMatchingOption}
                    buttonAnimation={buttonAnimation}
                  />
                  {isMatching && matchingAnswer && renderChosenValue()}
                </DropdownInputPopover>
              </div>
            );
          }}
        </Downshift>
      </Helper>
    );
  }
}

export default withTheme(DropdownInput);
