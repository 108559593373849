import initialState from 'store/initialState';
import { ActionTypes, WebSocketActionTypes } from './types';

export default function webSocketReducer(
  state = initialState.webSocket,
  action: WebSocketActionTypes
) {
  switch (action.type) {
    case ActionTypes.SET_WEBSOCKET_STATUS: {
      return {
        ...state,
        status: action.payload.status
      };
    }
    case ActionTypes.SET_AUTH_STATUS: {
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated
      };
    }
    default:
      return state;
  }
}
