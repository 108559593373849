import styled from 'styled-components';
import { transparentize } from 'utils/color';
import { reviewFontText } from 'components/mixins/reviewTypography';
import { transition } from 'components/mixins/interactivity';
import buttonDefaultStyle from 'components/mixins/normalize';
import media from 'components/mixins/media';
import { fontSizeAdjusted } from 'components/mixins/typography';

export const HintWrapper = styled.div`
  ${reviewFontText}
`;

export const HintContainer = styled.div`
  display: flex;
  align-items: center;
  width: 315px;
  padding: 19px 17px 17px 21px;

  ${media.mobile`
    width: 300px;
  `}
`;

export const HintElementsContainer = styled.div`
  width: 164px;
  margin-right: 24px;
  ${fontSizeAdjusted(12)};
  font-weight: 600;
  line-height: 1.42;
  text-align: left;
`;

export const HintButton = styled.button`
  ${buttonDefaultStyle};
  justify-self: flex-end;
  max-height: 32px;
  background: #4caf50;
  border-radius: 16px;
  padding: 11px 24px;
  color: #fff;
  ${fontSizeAdjusted(11)};
  line-height: 11px;
  cursor: pointer;
  box-shadow: 0 2px 8px 0 ${transparentize('#000000', 0.2)};
  ${transition(0.3)};

  &:hover {
    background: #46a14a;
  }
`;
