import { RootAppState } from 'store/types';

class StorageInterface {
  async getProgress(): Promise<any> {
    throw new Error('getProgress method in not implemented');
  }

  async saveProgress(options?: any, state?: RootAppState): Promise<any> {
    throw new Error('saveProgress method in not implemented');
  }

  async removeProgress(
    isStartOver: boolean,
    isCoursePassed: boolean,
    urlToNavigate: string
  ): Promise<any> {
    throw new Error('removeProgress method in not implemented');
  }
}

export default StorageInterface;
