import eventEmitter, { events } from 'core/events/eventEmitter';
import fetch from 'fetch-retry';
import originalFetch from 'isomorphic-fetch';

export const RETRY_DELAY_MILLISECONDS = 3000;

type RequestData = { [key: string]: any };
let lastRequestSuccess = true;
const CONTENT_TYPE = 'Content-Type';
const CONTENT_LENGTH = 'Content-Length';
const defaultCredentials = 'same-origin';

const defaultRetryOptions = {
  retryDelay: RETRY_DELAY_MILLISECONDS,
  retryOn(attempt: number, error: any, response: any) {
    if (error !== null || response.status >= 500) {
      lastRequestSuccess && eventEmitter.emit(events.APP_OFFLINE_OR_SERVER_ERROR);
      lastRequestSuccess = false;
      return true;
    }

    !lastRequestSuccess && eventEmitter.emit(events.APP_ONLINE);
    lastRequestSuccess = true;
    return false;
  }
};

const fetchRetry = fetch(originalFetch, defaultRetryOptions);

const getRequestOptions = (requestOptions: any, retryOptions: any) => ({
  ...retryOptions,
  ...requestOptions
});

export class Requester {
  public async get(
    url: string,
    {
      headers,
      cacheBuster,
      credentials = defaultCredentials,
      mode,
      query,
      retryOptions
    }: RequestData
  ) {
    const queryString = this._buildQueryString(query, cacheBuster);
    const requestOptions = {
      method: 'GET',
      headers: this._buildHeaders(headers, cacheBuster),
      credentials,
      mode
    };

    return fetchRetry(url + queryString, getRequestOptions(requestOptions, retryOptions));
  }

  public async post(
    url: string,
    {
      headers,
      cacheBuster,
      credentials = defaultCredentials,
      data,
      query,
      retryOptions,
      referrerPolicy
    }: RequestData
  ) {
    const body = JSON.stringify(data);
    const queryString = this._buildQueryString(query, cacheBuster);

    if (CONTENT_TYPE in headers) {
      headers[CONTENT_LENGTH] = body.length;
    }

    const requestOptions = {
      method: 'POST',
      headers: this._buildHeaders(headers, cacheBuster),
      body,
      credentials,
      referrerPolicy
    };

    return fetchRetry(url + queryString, getRequestOptions(requestOptions, retryOptions));
  }

  public async put(
    url: string,
    {
      headers,
      cacheBuster,
      credentials = defaultCredentials,
      data,
      query,
      retryOptions
    }: RequestData
  ) {
    const queryString = this._buildQueryString(query, cacheBuster);
    const body = JSON.stringify(data);
    if (CONTENT_TYPE in headers) {
      headers[CONTENT_LENGTH] = body.length;
    }

    const requestOptions = {
      method: 'PUT',
      headers: this._buildHeaders(headers, cacheBuster),
      body,
      credentials
    };

    return fetchRetry(url + queryString, getRequestOptions(requestOptions, retryOptions));
  }

  public async delete(
    url: string,
    {
      headers,
      cacheBuster,
      credentials = defaultCredentials,
      data,
      query,
      retryOptions
    }: RequestData
  ) {
    const queryString = this._buildQueryString(query, cacheBuster);
    const body = JSON.stringify(data);
    if (CONTENT_TYPE in headers) {
      headers[CONTENT_LENGTH] = body.length;
    }

    const requestOptions = {
      method: 'DELETE',
      headers: this._buildHeaders(headers, cacheBuster),
      body,
      credentials
    };

    return fetchRetry(url + queryString, getRequestOptions(requestOptions, retryOptions));
  }

  private _buildQueryString(query: any, cacheBuster: any) {
    if (!query) {
      query = {};
    }
    let queryString = '';

    query.v = cacheBuster || +new Date();
    for (let key of Object.keys(query)) {
      queryString += `&${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`;
    }
    if (queryString) {
      queryString = queryString.replace('&', '?');
    }

    return queryString;
  }

  private _buildHeaders(headers: { [key: string]: any } = {}, cacheBuster = '') {
    const _headers = Object.assign({}, headers);
    const CACHE_EXPIRATION_DATE_IN_SECONDS = 2678400;
    if (cacheBuster) {
      _headers['Cache-Control'] = 'public';
      _headers.Pragma = 'public';
      _headers.Expires = CACHE_EXPIRATION_DATE_IN_SECONDS;
    } else {
      _headers['Cache-Control'] = 'no-cache';
      _headers.Pragma = 'no-cache';
      _headers.Expires = 0;
    }

    return _headers;
  }
}

export default new Requester();
