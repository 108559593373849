import styled, { css } from 'styled-components';
import Icon from 'components/common/Icon';
import { transparentize } from 'utils/color';
import * as typography from 'components/mixins/typography';
import { centerAbsoluteElement } from 'components/mixins/placement';
import { defaultBoxShadowColor } from 'constants/common';

export const ArrowIcon = styled(Icon)`
  color: ${props => props.theme.colors.mainColor};
  position: absolute;
  ${centerAbsoluteElement};
`;

type DroppableItemStyleProps = {
  isMultipleList: boolean;
  isDragging: boolean;
  isPlaceholderVisible: boolean;
};
export const DroppableItemStyle = styled.div.attrs({
  'data-test': 'draggable-item'
})<DroppableItemStyleProps>`
  padding: 5px 30px 5px 40px;
  userselect: 'none';
  margin: 0 0 8px 0;
  position: relative;
  border-radius: 2px;
  color: ${props => props.theme.colors.textColor};
  width: 100%;
  cursor: move;
  user-select: none;
  transform: ${props => (props.isMultipleList && !props.isDragging ? 'none !important' : '')};
  ${props => (props.isMultipleList ? 'vertical-align: middle;' : '')};

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 15px;
    width: 12px;
    height: 2px;
    border-top: 2px solid ${props => transparentize(props.theme.colors.textColor, 0.2)};
    border-bottom: 2px solid ${props => transparentize(props.theme.colors.textColor, 0.2)};
    margin-top: -3px;
    transition: 0.2s;
  }

  &:hover {
    box-shadow: 0 1px 4px 0 ${transparentize(defaultBoxShadowColor, 0.1)};

    &:before {
      border-color: ${props => props.theme.colors.mainColor};
    }
  }

  ${props =>
    props.isDragging
      ? css`
          background: ${props.theme.colors.contentBodyColor};
          box-shadow: 0 2px 10px 0 ${transparentize(defaultBoxShadowColor, 0.1)};

          &:before {
            border-color: ${props.theme.colors.mainColor};
          }

          & + div {
            background: ${props.isPlaceholderVisible
              ? transparentize(props.theme.colors.textColor, 0.02)
              : 'transparent'};
            border-radius: 2px;
            vertical-align: middle;
            ${props.isMultipleList ? 'vertical-align: middle;' : ''};
          }
        `
      : css`
          background: ${transparentize(props.theme.colors.textColor, 0.06)};
        `};
`;

export const DroppableListStyle = styled.div.attrs({
  'data-test': 'droppable-area'
})`
  width: 100%;
`;

type DroppableItemWrapperProps = {
  isMultipleList: boolean;
  isAreaEmpty: boolean;
  position: any;
  isImageLoaded: boolean;
  isDropDisabled: boolean;
  width: number;
  widthInvisible: number;
};
export const DroppableItemWrapper = styled.div<DroppableItemWrapperProps>`
  display: flex;
  align-items: center;
  font-weight: bold;
  ${typography.secondaryFontText};

  ${props =>
    props.isMultipleList &&
    css`
      ${DroppableListStyle} {
        min-height: 40px;
        margin-bottom: 15px;
        width: 100%;

        & + div {
          display: none !important;
        }

        ${props.isAreaEmpty &&
          css`
            & {
              margin-bottom: 30px;
              background-color: ${transparentize(props.theme.colors.textColor, 0.04)};
            }

            &::after {
              display: block;
              content: attr(data-area-empty-text);
              ${typography.secondaryFont}
              font-weight: normal;
              ${typography.fontSizeAdjusted(14)};
              text-align: center;
              line-height: 40px;
              color: ${transparentize(
                props.theme.colors.textColor,
                props.theme.themeUtils.getOpacity(true, 0.4)
              )};
            }
          `}
      }

      ${DroppableItemStyle} {
        padding: 0;
        white-space: nowrap;
        display: inline-block;
        width: auto !important;
        margin-right: 15px;
        margin-bottom: 15px;
        max-width: 360px;

        & > div {
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 1.5;
          padding: 9px 30px 8px 40px;
        }
      }
    `};
  ${props =>
    props.position &&
    css`
      position: absolute;
      top: ${props.position.y}%;
      /*! @noflip */
      left: ${props.position.x}%;
      min-width: ${props.isDropDisabled ? '44' : props.width}px;
      background: ${transparentize(props.theme.colors.contentBodyColor, 0.9)};
      box-shadow: inset 0 2px 0 0 ${transparentize(defaultBoxShadowColor, 0.1)};
      border-radius: 2px;
      transition: 0.3s;
      transform: ${!props.isImageLoaded ? 'scale(0)' : ''};
      opacity: ${!props.isImageLoaded ? '0.8' : '1'};

      ${!props.isDropDisabled &&
        css`
          &:after {
            content: '0';
            display: block;
            ${typography.secondaryFontText};
            line-height: 1.5;
            padding: 9px 0 8px;
            color: transparent;
          }
        `}

      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: ${props.width === 44
          ? 'transparent'
          : transparentize(props.theme.colors.mainColor, 0.25)};
        transition: 0.3s;
      }

      & > div {
        top: 0;
        ${props.isDropDisabled ? '' : 'position: absolute'};
        transition: 0.3s;
        min-width: ${props.isDropDisabled ? '44' : props.widthInvisible}px;
        height: 100%;

        & + div {
          display: none !important;
        }
      }

      & ${DroppableItemStyle} {
        background: ${props.theme.colors.contentBodyColor};
        margin-bottom: 0;
        box-shadow: 0 1px 3px 0 ${transparentize(defaultBoxShadowColor, 0.04)};
      }
    `};
`;

type ImageWrapperProps = {
  isImageLoaded?: boolean;
  isImageNotFound?: boolean;
};
export const ImageWrapper = styled.div.attrs({
  'data-test': 'image-wrapper'
})<ImageWrapperProps>`
  position: relative;
  display: inline-block;
  height: ${props => (props.isImageLoaded ? 'auto' : '182px')};
  width: max-content;
  max-width: 100%;
  background: ${props =>
    props.isImageNotFound || !props.isImageLoaded
      ? transparentize(props.theme.colors.textColor, 0.02)
      : 'transparent'};
  padding: ${props => (props.isImageNotFound ? '16px 0' : '0')};

  ${DroppableListStyle} {
    min-height: auto;
    margin-right: 0;
    margin-bottom: 0;
  }

  ${DroppableItemStyle} {
    margin-right: 0;
  }

  img {
    display: block;
    opacity: ${props => (props.isImageLoaded ? '1' : '0')};
    max-width: 100%;
    margin: ${props => (props.isImageNotFound ? '0 auto' : '0')};
  }
`;
