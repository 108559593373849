import { POPUP_ID_START_OVER, POPUP_ID_TIMED_OUT } from 'constants/popups';
import initialState from 'store/initialState';
import { ActionTypes, PopupActionTypes } from './types';

export default function popupReducer(state = initialState.popup, action: PopupActionTypes) {
  switch (action.type) {
    case ActionTypes.OPEN_POPUP:
      return {
        ...state,
        ...{
          isExpanded: [POPUP_ID_START_OVER, POPUP_ID_TIMED_OUT].includes(
            action.payload.settings.popupId
          )
            ? true
            : !state.isExpanded,
          settings: action.payload.settings
        }
      };
    case ActionTypes.CLOSE_POPUP:
      delete state.settings;
      return { ...state, ...{ isExpanded: !state.isExpanded } };
    case ActionTypes.OPEN_ACCESSIBILITY_USER_MENU_SECTION:
      return {
        ...state,
        ...{ isAccessibilityExpanded: true }
      };
    case ActionTypes.CLOSE_ACCESSIBILITY_USER_MENU_SECTION:
      return { ...state, ...{ isAccessibilityExpanded: false } };
    default:
      return state;
  }
}
