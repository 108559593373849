import * as React from 'react';
import { withTheme } from 'styled-components';
import throttle from 'lodash.throttle';
import SVG from 'react-inlinesvg';
import MinusSign from 'assets/icons/custom-icons/font-adjustment-decrease.svg';
import PlusSign from 'assets/icons/custom-icons/font-adjustment-increase.svg';

import Icon from 'components/common/Icon';
import { localize } from 'core/localization';
import {
  AccessibilityOptionDetails,
  AccessibilityState as AccessibilityStoreState,
  FONT_SIZE_ACTION
} from 'store/accessibility/types';
import {
  accessibilityOptionsList,
  FONT_ADJUSTMENT_ICON_SIZES,
  FONT_SIZE_ADJUSTMENT_OPTIONS,
  FONT_SIZE_NAMES,
  USER_MENU_ICON_SIZES
} from 'constants/accessibility';

import {
  AccessibilityContainer,
  AccessibilityOptionContainer,
  AccessibilityOption,
  AccessibilityOptionText,
  FontSizeAdjustmentContainer,
  FontSizeAdjustmentButton,
  FontSizeAdjustmentOptionText,
  AccessibilityDescription
} from './Accessibility.styled';

type AccessibilityProps = {
  theme?: any;
  visible: boolean;
  updateAccessibilitySettings: (newAccessibilitySettings: AccessibilityStoreState) => void;
  onAccessibilityDescription: () => void;
  accessibilitySettings: AccessibilityStoreState;
};

type AccessibilityState = {};

export class Accessibility extends React.Component<AccessibilityProps, AccessibilityState> {
  onToggleSetting = throttle((key: keyof AccessibilityStoreState, customValue?: number) => {
    const value = customValue !== undefined ? customValue : !this.props.accessibilitySettings[key];
    this.props.updateAccessibilitySettings({
      ...this.props.accessibilitySettings,
      [key]: value
    });
  }, 300);
  onUpdateFontSize = throttle((action: FONT_SIZE_ACTION) => {
    const key = 'fontSize';
    const oldValue = this.props.accessibilitySettings[key];
    const adder = action === FONT_SIZE_ACTION.INCREASE ? 1 : -1;
    const newValue = oldValue + adder;

    if (FONT_SIZE_ADJUSTMENT_OPTIONS[newValue]) {
      this.onToggleSetting(key, newValue);
    }
  }, 300);

  descriptionLinkRef: any = React.createRef();

  onDescriptionClick = (e: any) => {
    e.preventDefault();
    const { onAccessibilityDescription } = this.props;
    const linkElement = document.getElementById('accessibility-description-link');
    if (linkElement && e.target === linkElement) {
      onAccessibilityDescription();
    }
  };

  getAccessibilityOption = (option: AccessibilityOptionDetails, templateTheme: any) => {
    const accessibility = this.props.accessibilitySettings;
    return (
      <AccessibilityOptionContainer
        data-test={option['data-test']}
        tabIndex={this.props.visible ? 0 : -1}
        fullWidth={option.fullWidth || false}
        key={option.key}
        visible={option.visible || false}
        accessibility={accessibility}
        enabled={accessibility[option.key] === true}
        onClick={() => !option.showFontSizeAdjustment && this.onToggleSetting(option.key)}
      >
        <AccessibilityOption>
          <Icon
            name={option.icon.name}
            size={USER_MENU_ICON_SIZES.ACCESSIBILITY[accessibility.fontSize]}
          />
          {option.showFontSizeAdjustment ? (
            <>
              <FontSizeAdjustmentOptionText accessibility={accessibility}>
                {localize(option.label)}
              </FontSizeAdjustmentOptionText>
              <FontSizeAdjustmentContainer accessibility={accessibility}>
                <FontSizeAdjustmentButton
                  font-size-action={FONT_SIZE_ACTION.DECREASE}
                  accessibility={accessibility}
                  onClick={() => this.onUpdateFontSize(FONT_SIZE_ACTION.DECREASE)}
                >
                  <SVG
                    src={MinusSign}
                    width={FONT_ADJUSTMENT_ICON_SIZES.DECREASE[accessibility.fontSize]}
                    height={FONT_ADJUSTMENT_ICON_SIZES.DECREASE[accessibility.fontSize]}
                  />
                </FontSizeAdjustmentButton>
                {localize(FONT_SIZE_NAMES[accessibility['fontSize']])}
                <FontSizeAdjustmentButton
                  font-size-action={FONT_SIZE_ACTION.INCREASE}
                  accessibility={accessibility}
                  onClick={() => this.onUpdateFontSize(FONT_SIZE_ACTION.INCREASE)}
                >
                  <SVG
                    src={PlusSign}
                    width={FONT_ADJUSTMENT_ICON_SIZES.INCREASE[accessibility.fontSize]}
                    height={FONT_ADJUSTMENT_ICON_SIZES.DECREASE[accessibility.fontSize]}
                  />
                </FontSizeAdjustmentButton>
              </FontSizeAdjustmentContainer>
            </>
          ) : (
            <AccessibilityOptionText accessibility={accessibility}>
              {localize(option.label)}
            </AccessibilityOptionText>
          )}
        </AccessibilityOption>
      </AccessibilityOptionContainer>
    );
  };

  render() {
    const { visible, theme, accessibilitySettings } = this.props;
    return (
      <AccessibilityContainer visible={visible}>
        {accessibilityOptionsList.map((option: AccessibilityOptionDetails) =>
          this.getAccessibilityOption(option, theme)
        )}
        <AccessibilityDescription
          ref={this.descriptionLinkRef}
          onClick={this.onDescriptionClick}
          accessibility={accessibilitySettings}
          dangerouslySetInnerHTML={{
            __html: `${localize(`[wcag feature description]`, {
              link: `<button id="accessibility-description-link" tabIndex=${
                visible ? 0 : -1
              }>${localize(`[wcag feature description link]`)}</button>`
            })}`
          }}
        ></AccessibilityDescription>
      </AccessibilityContainer>
    );
  }
}
export default withTheme(Accessibility);
