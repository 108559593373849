import { RootAppState } from '../types';
import {
  isAnswersFromPreviousAttemptEnabled,
  shouldSubmitAllQuestions
} from '../settings/selectors';
import { getCourseAttempt, isAttemptSubmitted } from '../course/selectors';

export const hasGuidedReattempt = (state: RootAppState) => {
  const shouldShowPreviousAnswers = isAnswersFromPreviousAttemptEnabled(state);
  const courseAttempts = getCourseAttempt(state);
  const isSubmitAtOnce = shouldSubmitAllQuestions(state);
  const isCourseSubmitted = isAttemptSubmitted(state);
  return isSubmitAtOnce && !isCourseSubmitted && shouldShowPreviousAnswers && courseAttempts > 0;
};
