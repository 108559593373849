import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { localize } from 'core/localization';
import { RootAppState } from 'store/types';
import { Button } from 'components/common';
import { isScormMode, getLearnServiceUrl, isPreviewMode, isReviewMode } from 'store/settings/selectors';
import * as navigationActions from 'store/navigation/actions';
import { isLtiInitialized } from 'store/modules/selectors';
import { isAnonymous } from 'store/user/selectors';
import { TextElement, Wrapper, ButtonsWrapper } from './CloseCourse.styled';

type CloseCourseProps = {
  scormMode: boolean;
  isLti: boolean;
  previewMode: boolean;
  reviewMode?: boolean;
  learnServiceUrl?: string;
  navigationActions: { [key: string]: any };
  isAnonymous: boolean;
};

export class CloseCourse extends React.PureComponent<CloseCourseProps, {}> {
  renderCourseCloseText = () => {
    const { isLti, isAnonymous, reviewMode } = this.props;
    if (isAnonymous) {
      return localize('[close anonymous user course text]');
    }
    return isLti || reviewMode ? localize('[close lti course text]') : localize('[close course text]');
  };

  render() {
    const {
      scormMode,
      navigationActions,
      learnServiceUrl,
      previewMode,
      reviewMode,
      isLti,
      isAnonymous
    } = this.props;
    return (
      <Wrapper>
        <TextElement appearance="span">{this.renderCourseCloseText()}</TextElement>
        {!scormMode && !previewMode && !reviewMode && !isLti && !isAnonymous && (
          <ButtonsWrapper>
            <Button
              onClick={() => navigationActions.goToUrl(learnServiceUrl)}
              data-test="my-courses-button"
            >
              {localize('[my courses]')}
            </Button>
          </ButtonsWrapper>
        )}
      </Wrapper>
    );
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    learnServiceUrl: getLearnServiceUrl(state),
    scormMode: isScormMode(state),
    isLti: isLtiInitialized(state),
    previewMode: isPreviewMode(state),
    reviewMode: isReviewMode(state),
    isAnonymous: isAnonymous(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    navigationActions: bindActionCreators(navigationActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CloseCourse);
