import isEmpty from 'lodash.isempty';

export function getDOMNode(embedCode: string): any {
  const nodeElement = new DOMParser().parseFromString(embedCode, 'text/html');
  return nodeElement.body.firstChild;
}

export function getAttributesNodeElement(elem: any) {
  const attributes = elem.attributes; // eslint-disable-line
  const length = attributes.length; // eslint-disable-line

  const result = new Array(length);
  for (let i = 0; i < length; i++) {
    result[i] = attributes[i].name;
  }
  return result;
}

type AddAriaLiveProps = {
  id: string;
  attributes: { key: string; value: string }[];
};

export function addAriaLive({ id, attributes }: AddAriaLiveProps) {
  if (document.getElementById(id)) {
    return;
  }
  const ariaDiv = document.createElement('div');
  const ariaP = document.createElement('p');
  ariaDiv.setAttribute('id', id);
  if (attributes && !isEmpty(attributes)) {
    attributes.forEach(attr => {
      ariaDiv.setAttribute(attr.key, attr.value);
    });
  }
  ariaDiv.style.position = 'absolute';
  ariaDiv.style.top = '-1000px';
  ariaDiv.style.height = '0px';
  ariaP.style.height = '0px';
  ariaDiv.appendChild(ariaP);
  document.body.appendChild(ariaDiv);
}
