import * as userActions from 'store/user/actions';
import { getCourse, getCourseId } from 'store/course/selectors';
import { getValueFromUrl } from 'utils/window';
import { getUser, getName, getEmail, getAccount } from 'store/user/selectors';
import {
  isNpsEnabled,
  getXapiSettings,
  getPublishSettings,
  isResultsTrackingEnabled,
  getAuthoringToolUrl,
  isScorm2004Mode,
  isScoringOfContentPagesAllowed,
  getLxpId,
  getLxpSettings,
  shouldSubscribeToLxp,
  getAuthServiceUrl
} from 'store/settings/selectors';
import progressStorage from 'core/progressStorage';
import eventEmitter, { events } from 'core/events/eventEmitter';
import { loadReviewFont } from 'components/mixins/reviewTypography';
import { isPreviewMode, isReviewMode } from '../settings/selectors';
import lti from '../../core/lti';

import { ActionTypes } from './types';
import { ActionTypes as CommentActionTypes } from '../comments/types';
import { ActionTypes as UserActionTypes } from '../user/types';
import { ThunkResult } from '../types';
import { IdToUuid } from 'utils/string';
import { isLrsTrackingOn, isLxpTrackingOn, isNpsTrackingOn } from './selectors';
import { LAYOUT } from 'constants/review';

export const initializeXapi = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  const { default: xApi } = await import('core/xApi');
  xApi.initialize({
    settings: {
      xApi: getXapiSettings(state),
      isScoringOfContentPagesAllowed: isScoringOfContentPagesAllowed(state),
      lxp: getLxpSettings(state)
    },
    defaultLrsUrl: getPublishSettings(state).defaultLRSUrl,
    defaultNpsUrl: getPublishSettings(state).defaultNpsUrl,
    defaultLxpUrl: getPublishSettings(state).lxpServiceUrl,
    course: getCourse(state),
    actor: {
      username: getName(state),
      email: getEmail(state),
      account: getAccount(state)
    }
  });
  dispatch({ type: ActionTypes.MODULE_XAPI_INITIALIZED });
};

export const subscribeXapi = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const { default: xApi } = await import('core/xApi');
  if (isResultsTrackingEnabled(getState()) && !isLrsTrackingOn(getState())) {
    xApi.subscribeLrs();
    dispatch({ type: ActionTypes.MODULE_XAPI_LRS_ON });
  }
  if (isNpsEnabled(getState()) && !isNpsTrackingOn(getState())) {
    xApi.subscribeNps();
    dispatch({ type: ActionTypes.MODULE_XAPI_NPS_ON });
  }

  if (shouldSubscribeToLxp(getState()) && !isLxpTrackingOn(getState())) {
    const lxpId = getLxpId(getState());
    xApi.subscribeLxp(lxpId);
    dispatch({ type: ActionTypes.MODULE_XAPI_LXP_ON });
  }
};

export const unsubscribeXapi = (): ThunkResult<Promise<void>> => async dispatch => {
  const { default: xApi } = await import('core/xApi');
  xApi.unsubscribeAll();

  dispatch({ type: ActionTypes.MODULE_XAPI_LRS_OFF });
  dispatch({ type: ActionTypes.MODULE_XAPI_NPS_OFF });
  dispatch({ type: ActionTypes.MODULE_XAPI_LXP_OFF });
};

// TODO: need refactor
export const initializeScorm = (isReAttempt: boolean): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  const { default: scorm } = await import('core/scorm');
  await scorm.initialize(isScorm2004Mode(getState()));
  dispatch({ type: ActionTypes.MODULE_SCORM_INITIALIZED });
  dispatch(
    userActions.authenticate({
      name: scorm.userInfoProvider.getUsername(),
      email: scorm.userInfoProvider.getAccountId(),
      account: {
        homePage: scorm.userInfoProvider.getAccountHomePage(),
        name: scorm.userInfoProvider.getAccountId()
      }
    })
  );
  if (!isReAttempt) {
    await eventEmitter.emit(events.APP_INITIALIZED, { state: getState(), isScormMode: true });
  }
  await eventEmitter.emit(events.USER_AUTHENTICATED, getUser(getState()));
  progressStorage.use(scorm.progressProvider);
};

export const subscribeProgressStorage = (): ThunkResult => (dispatch, getState) => {
  if (isPreviewMode(getState()) || isReviewMode(getState())) {
    return;
  }

  progressStorage.subscribe();
  dispatch({ type: ActionTypes.MODULE_PROGRESS_STORAGE_ON });
};

export const unsubscribeProgressStorage = (): ThunkResult => dispatch => {
  progressStorage.unsubscribe();
  dispatch({ type: ActionTypes.MODULE_PROGRESS_STORAGE_OFF });
};

export const enableReview = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const editorWindow = window.parent;
  const locationProtocol = window.location.protocol;
  const targetOriginUrl = `${locationProtocol}//${getAuthoringToolUrl(getState())}`;
  if (window.location.href.includes('.easygenerator.com/')) {
    editorWindow.postMessage({ supportsNativeReview: true }, targetOriginUrl);
  }
  await loadReviewFont();
  const reviewApiUrl = getValueFromUrl('reviewApiUrl');
  const authoringToolDomain = getValueFromUrl('authoringToolDomain');
  const courseId = getCourseId(getState());
  const authServiceUrl = getAuthServiceUrl(getState());
  const { default: review } = await import('core/review');
  const { default: reviewCore } = await import('core/review/review');
  review.subscribe();
  const { webSocket } = await import('core/websocket');
  webSocket.setSocketEventListeners(review.socketHandlers);
  webSocket.socket.on('connect', () => {
    webSocket.socket.emit('initialize', IdToUuid(courseId), {}, reviewCore.token);
  });

  webSocket.socket.on('reconnect', () => {
    eventEmitter.emit(events.COMMENTS_LIST);
    webSocket.socket.emit('initialize', IdToUuid(courseId), {}, reviewCore.token);
  });

  dispatch({ type: ActionTypes.ENABLE_REVIEW_MODE, payload: { reviewApiUrl } as any });
  await eventEmitter.emit(events.REVIEW_INITIALIZED, {
    reviewApiUrl,
    authServiceUrl,
    courseId,
    authoringToolDomain
  });
};

export const updateReviewLayout = (layout: LAYOUT): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  dispatch({ type: ActionTypes.UPDATE_REVIEW_LAYOUT, payload: { layout } as any });
};

export const resetReviewLayout = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  dispatch({ type: CommentActionTypes.COMMENTS_LIST, payload: { comments: [] } });
  dispatch({ type: UserActionTypes.USER_UNAUTHENTICATED });
  dispatch({
    type: ActionTypes.UPDATE_REVIEW_LAYOUT,
    payload: { layout: LAYOUT.LOGIN_WITH_EMAIL } as any
  });
};

export const subscribeLti = (callbackUrl: any): ThunkResult => dispatch => {
  lti.subscribe(callbackUrl);
  dispatch({ type: ActionTypes.MODULE_LTI_INITIALIZED, payload: { isLTI: true } });
};
