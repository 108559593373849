import {
  getAuthServiceUrl,
  shouldSubmitAllQuestions,
  getLearnServiceUrl
} from 'store/settings/selectors';
import {
  getCourseTitle,
  getCourseId,
  getTemplateId,
  isInProgress,
  isPassed,
  isFailed,
  getScore,
  isAttemptSubmitted,
  isCourseAccessLimited
} from 'store/course/selectors';
import requester from 'core/http/requester';
import { CONTENT_TYPES } from 'constants/common';
import { IdToUuid } from 'utils/string';
import { getCurrentUrl } from 'utils/window';
import StorageInterface from '../storageInterface';
import ExternalStorageAuth from './auth';
import SocialNetworks from './socialNetworks';
import { RootAppState } from 'store/types';
import { CourseProgressStorageStatus } from 'constants/progressStatus';
import { isAuthenticated } from 'store/user/selectors';

class ExternalStorage extends StorageInterface {
  courseId: string;
  templateId: string;
  learnServiceUrl: string;
  authServiceUrl: string;
  private readonly _auth: any;
  private readonly _socialNetworks: any;

  constructor(state: RootAppState) {
    super();

    this.courseId = getCourseId(state);
    this.templateId = getTemplateId(state);
    this.learnServiceUrl = getLearnServiceUrl(state);
    this.authServiceUrl = getAuthServiceUrl(state);

    const courseTitle = getCourseTitle(state);

    this._auth = new ExternalStorageAuth(courseTitle, this.authServiceUrl, this.learnServiceUrl);
    this._socialNetworks = new SocialNetworks(courseTitle, this.authServiceUrl);
  }

  async getProgress() {
    if (!this.getToken()) {
      return;
    }

    const responseProgress = await requester.get(
      `${this.learnServiceUrl}/api/learner/me/courses/${IdToUuid(this.courseId)}/attempts/last`,
      {
        query: {
          templateId: this.templateId
        },
        headers: this.getHeaders({ bearerToken: this.getToken() })
      }
    );
    if (responseProgress.status === 201 || responseProgress.status === 200) {
      const data = await responseProgress.json();
      try {
        return data && data.jsonProgress && JSON.parse(data.jsonProgress);
      } catch (e) {
        console.error(e);
        return {};
      }
    }

    return requester.post(
      `${this.learnServiceUrl}/api/learner/me/courses/${IdToUuid(this.courseId)}/attempts`,
      {
        data: {
          templateId: this.templateId,
          jsonProgress: '{}',
          score: 0,
          status: CourseProgressStorageStatus.IN_PROGRESS,
          courseUrl: getCurrentUrl()
        },
        headers: this.getHeaders({ contentType: CONTENT_TYPES.JSON, bearerToken: this.getToken() }),
        nocache: true
      }
    );
  }

  async saveProgress(progress: any, state: RootAppState) {
    if (!isAuthenticated(state)) {
      return false;
    }
    if (isCourseAccessLimited(state)) {
      return false;
    }

    let status = CourseProgressStorageStatus.IN_PROGRESS;
    if (isPassed(state)) {
      status = CourseProgressStorageStatus.PASSED;
    }
    if (isFailed(state)) {
      status = CourseProgressStorageStatus.FAILED;
    }
    if (isAttemptSubmitted(state) && isInProgress(state) && shouldSubmitAllQuestions(state)) {
      status = CourseProgressStorageStatus.FAILED;
    }

    const saveProgressResponse = await requester.put(
      `${this.learnServiceUrl}/api/learner/me/courses/${IdToUuid(this.courseId)}/attempts/last`,
      {
        data: {
          jsonProgress: progress ? JSON.stringify(progress) : '{}',
          score: getScore(state),
          status
        },
        query: {
          templateId: this.templateId
        },
        headers: this.getHeaders({ contentType: CONTENT_TYPES.JSON, bearerToken: this.getToken() }),
        nocache: true
      }
    );
    if (saveProgressResponse.status === 401 || saveProgressResponse.status === 403) {
      return this.logout();
    }
    return saveProgressResponse;
  }

  async removeProgress() {
    return requester.post(
      `${this.learnServiceUrl}/api/learner/me/courses/${IdToUuid(this.courseId)}/attempts`,
      {
        data: {
          templateId: this.templateId,
          jsonProgress: '{}',
          score: 0,
          status: CourseProgressStorageStatus.IN_PROGRESS,
          courseUrl: getCurrentUrl()
        },

        headers: this.getHeaders({ contentType: CONTENT_TYPES.JSON, bearerToken: this.getToken() }),
        nocache: true
      }
    );
  }

  isAuthenticated() {
    return this._auth.isAuthenticated();
  }

  async userExists(email: string) {
    return this._auth.exists(email);
  }

  async identifyUser() {
    return this._auth.identify();
  }

  async authorizeUser(userData: any) {
    return this._auth.authorize(userData);
  }

  async registerUser(userData: any) {
    return this._auth.register(userData);
  }

  async resetPassword(email: string) {
    return this._auth.resetPassword(email);
  }

  async logout() {
    return this._auth.logout();
  }

  getSocialNetworkAuthLink(socialNetwork: any) {
    return this._socialNetworks.getAuthLink(socialNetwork);
  }

  async sendSecretLink() {
    return this._auth.sendSecretLink(this.courseId);
  }

  getHeaders({ contentType, bearerToken }: any) {
    return this._auth.getHeaders({ contentType, bearerToken });
  }

  getToken() {
    return this._auth.token;
  }
}

export default ExternalStorage;
