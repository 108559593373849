import { RootAppState } from '../types';

export const isAppLoaded = (state: RootAppState) => state.app.isLoaded;
export const isAppLoadingFailed = (state: RootAppState) => state.app.isLoadingFailed;
export const isAppLoading = (state: RootAppState) => state.app.isLoading;
export const isLowResolution = (state: RootAppState) => state.app.isLowResolution;
export const isOffline = (state: RootAppState) => state.app.isOffline;
export const isLrsMisconfigurationError = (state: RootAppState) =>
  state.app.isLrsMisconfigurationError;
export const isAppError = (state: RootAppState) => state.app.isError;
