import styled, { css } from 'styled-components';
import media from 'components/mixins/media';
import { bodyBackground } from 'components/mixins/backgrounds';
import { Layout, BasicStyledSidebar, PageContent } from 'components/shell/Layout';
import { PageContentChild } from 'components/shell/Layout/Layout.styled';
import { centerAbsoluteElement } from 'components/mixins/placement';
import Icon from 'components/common/Icon';
import { getCustomScrollbars } from 'components/mixins/customScrollbars';
import { transparentize } from 'utils/color';
import { fontSizeAdjusted } from 'components/mixins/typography';

export const PageSidebar = styled(BasicStyledSidebar)``;

export const LayoutWrapper = styled(Layout)``;

type PageContentWrapperProps = {
  background: any;
};
export const PageContentWrapper = styled(PageContent)<PageContentWrapperProps>`
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 50%;
  z-index: 100;
  overflow-y: auto;
  ${getCustomScrollbars()}
  ${props => bodyBackground(props.background.body)};
  ${media.tablet`
    position: relative;
    width: 100%;
    left: 0;
  `}
  ${PageContentChild} {
    ${media.tablet`
      height: 100%;
  `}
  }
`;

export const PageSection = styled.section`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

type LogoProps = {
  altText: string;
  logoUrl: string;
};
export const LogoSidebar = styled.img.attrs((props: LogoProps) => ({
  alt: props.altText,
  src: props.logoUrl
}))<LogoProps>`
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
`;

export const LogoSidebarWrapper = styled.div`
  width: 360px;
  height: 200px;
  position: absolute;
  ${centerAbsoluteElement};
`;

export const ErrorContainer = styled.div`
  width: 440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${props => transparentize(props.theme.colors.textColor, 0.1)};
  ${media.mobile`
    & {
      width: 100%;
      padding: 0 20px;
    }
  `};
`;

export const ErrorIcon = styled(Icon)`
  opacity: 0.5;
`;

export const ErrorTitle = styled.h1`
  word-wrap: break-word;
  ${fontSizeAdjusted(32)};
  text-align: center;
  line-height: 1.4;
  margin: 40px 0 16px;
  width: 100%;
  font-family: ${props => props.theme.fonts.mainFont.fontFamily};
  color: ${props => props.theme.colors.textColor};
  ${media.medium`
    ${css`
      & {
        ${fontSizeAdjusted(28)};
      }
    `}
  `};
  ${media.mobile`
    ${css`
      & {
        ${fontSizeAdjusted(22)};
      }
    `}
  `};
`;

export const ErrorDescription = styled.h3`
  width: 100%;
  opacity: 0.7;
  ${fontSizeAdjusted(16)};
  line-height: 1.4;
  text-align: center;
  margin-bottom: 82px;
  font-family: ${props => props.theme.fonts.mainFont.fontFamily};
  color: ${props => props.theme.colors.textColor};
  ${media.medium`
    ${css`
      & {
        ${fontSizeAdjusted(14)};
      }
    `}
  `};
  ${media.mobile`
      ${css`
        & {
          ${fontSizeAdjusted(12)};
        }
      `}
  `};
`;
