import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { localize } from 'core/localization';
import {
  isSettingsLoadingFailed,
  getBackground,
  getAlternativeLogo,
  getColors,
  getFonts,
  isTrackingEnabled,
  isScormMode,
  isResource
} from 'store/settings/selectors';
import { getPopupVisibility } from 'store/popup/selectors';
import { isAuthenticated } from 'store/user/selectors';
import { getReviewLayout, isReviewEnabled } from 'store/modules/selectors';
import { isCourseAccessLimited } from 'store/course/selectors';
import { RootAppState } from 'store/types';
import {
  DEFAULT_SOMETHING_WENT_WRONG_TITLE_TEXT,
  DEFAULT_LOGO_TEXT,
  DEFAULT_PLEASE_CONTACT_SUPPORT_TEXT
} from 'constants/defaults';
import withNavigation from 'components/hocs/withNavigation';
import { getAccessibilitySettings } from 'store/accessibility/selectors';
import { SUPPORT_EMAIL } from 'constants/common';
import { Redirect, withRouter } from 'react-router-dom';
import { LOGIN_PATH } from 'constants/navigation';
import {
  LayoutWrapper,
  PageSidebar,
  PageContentWrapper,
  LogoSidebar,
  LogoSidebarWrapper,
  PageSection,
  ErrorContainer,
  ErrorIcon,
  ErrorTitle,
  ErrorDescription
} from './SomethingWentWrongPage.styled';
import { LAYOUT } from 'constants/review';

type SomethingWentWrongPageProps = {
  settingsLoadingFailed: boolean;
  background: { [key: string]: any };
  alternativeLogoUrl: string;
  colors: any;
  fonts: any;
  isUserAuthenticated: boolean;
  popupExpanded: boolean;
  trackingEnabled: boolean;
  isAccessLimited: boolean;
  isScorm: boolean;
  reviewMode: boolean;
  reviewLayout: LAYOUT;
  hasUnhandledError: boolean;
  isResourceType: boolean;
  accessibilitySettings: any;
  navigateToIndex: () => void;
  isRtl: boolean;
};

export class SomethingWentWrongPage extends PureComponent<SomethingWentWrongPageProps, {}> {
  componentDidMount() {
    document.title = !this.props.settingsLoadingFailed
      ? localize('[something went wrong title]')
      : DEFAULT_SOMETHING_WENT_WRONG_TITLE_TEXT;
    this.props.navigateToIndex();
  }

  getContactSupportText = () => {
    const localizedText = localize('[contact]', {
      supportEmail: `<a id="support-email-link" href="mailto:${SUPPORT_EMAIL}}">${SUPPORT_EMAIL}</a>`
    });
    return localizedText || DEFAULT_PLEASE_CONTACT_SUPPORT_TEXT;
  };

  render() {
    const {
      settingsLoadingFailed,
      background,
      alternativeLogoUrl,
      isUserAuthenticated
    } = this.props;

    if (!isUserAuthenticated) {
      return <Redirect to={LOGIN_PATH} />;
    }
    return (
      <Fragment>
        <LayoutWrapper {...this.props}>
          <PageSidebar background={background}>
            <LogoSidebarWrapper>
              <LogoSidebar
                altText={!settingsLoadingFailed ? localize('[logo]') : DEFAULT_LOGO_TEXT}
                logoUrl={alternativeLogoUrl}
              />
            </LogoSidebarWrapper>
          </PageSidebar>
          <PageContentWrapper background={background}>
            <PageSection>
              <ErrorContainer>
                <ErrorIcon name="attention" size={64} color="textColor" />
                <ErrorTitle>
                  {!settingsLoadingFailed
                    ? localize('[something went wrong title]')
                    : DEFAULT_SOMETHING_WENT_WRONG_TITLE_TEXT}
                </ErrorTitle>
                <ErrorDescription
                  dangerouslySetInnerHTML={{ __html: this.getContactSupportText() }}
                ></ErrorDescription>
              </ErrorContainer>
            </PageSection>
          </PageContentWrapper>
        </LayoutWrapper>
      </Fragment>
    );
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    settingsLoadingFailed: isSettingsLoadingFailed(state),
    background: getBackground(state),
    alternativeLogoUrl: getAlternativeLogo(state),
    colors: getColors(state),
    fonts: getFonts(state),
    popupExpanded: getPopupVisibility(state),
    isUserAuthenticated: isAuthenticated(state),
    trackingEnabled: isTrackingEnabled(state),
    isAccessLimited: isCourseAccessLimited(state),
    isScorm: isScormMode(state),
    reviewMode: isReviewEnabled(state),
    reviewLayout: getReviewLayout(state),
    isResourceType: isResource(state),
    accessibilitySettings: getAccessibilitySettings(state)
  };
}

export default withRouter(withNavigation(connect(mapStateToProps)(SomethingWentWrongPage)));
