import styled from 'styled-components';
import { transparentize } from 'utils/color';

type ContainerProps = {
  isTimerStopped: boolean;
};
export const Container = styled.div<ContainerProps>`
  width: 22px;
  height: 22px;
  margin: ${props => (props.isTimerStopped ? '9px 7px 9px 8px' : '9px 11px 11px 16px')};
`;

export const ProgressContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Progress = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${props =>
    transparentize(props.theme.colors.textColor, props.theme.themeUtils.getOpacity(true, 0.5))};
`;
