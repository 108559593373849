export enum WebSocketStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  CONNECTION_ERROR = 'CONNECTION_ERROR'
}

export enum ActionTypes {
  SET_WEBSOCKET_STATUS = 'SET_WEBSOCKET_STATUS',
  SET_AUTH_STATUS = 'SET_AUTH_STATUS'
}

interface UpdateStatus {
  type: ActionTypes.SET_WEBSOCKET_STATUS;
  payload: {
    status: WebSocketStatus;
  };
}

interface UpdateAuthStatus {
  type: ActionTypes.SET_AUTH_STATUS;
  payload: {
    isAuthenticated: boolean;
  };
}

export type WebSocketActionTypes =
  | UpdateStatus
  | UpdateAuthStatus;

export type WebSocketState = {
  status: WebSocketStatus;
  isAuthenticated: boolean
};
