import styled, { css } from 'styled-components';

export const ImageItem = styled.figure`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 30px;
`;

type ImageProps = {
  alt: string;
  isImageLoaded: boolean;
};
export const Image = styled.img.attrs((props: ImageProps) => ({
  alt: props.alt,
  'data-test': 'preview-gallery-image'
}))<ImageProps>`
  max-width: 100%;
  max-height: 100%;
  display: block;
  z-index: 1;

  ${props =>
    props.isImageLoaded
      ? css`
          & {
            display: block;
          }
        `
      : css`
          display: none;
        `};
`;
