import { css } from 'styled-components';
import media from 'components/mixins/media';
import 'focus-visible';

const commonCSS = css`
  * {
    box-sizing: border-box;
    word-wrap: break-word;
    -webkit-overflow-scrolling: touch; /* smooth scrolling on safari */
  }
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    font-size: 16px;
    line-height: 32px;
  }

  &:focus {
    outline: 1px solid black;
  }

  &.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  div.resource-layout-offset {
    margin-top: 76px;
    height: calc(100% - 76px);
  }

  .div-inline-block {
    width: 100%;
    display: inline-block;
  }

  .z-index-3 {
    z-index: 3;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .cropped-image:hover .zoom-button {
    display: block;
    opacity: 0.8;
  }

  ${media.small`
    div.resource-layout-offset {
      margin-top: 62px;
      height: calc(100% - 62px);
    }
  `}
`;

export default commonCSS;
