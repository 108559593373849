import { getQuestion } from 'store/questions/selectors';
import { isRandomizeAnswersEnabled } from 'store/settings/selectors';
import { RootAppState } from '../../types';

export const getUserAnswers = (state: RootAppState, questionId: string) => {
  const question = getQuestion(state, questionId);
  const randomizeAnswersEnabled = isRandomizeAnswersEnabled(state);
  const { answers: options, randomizedOptions = [] } = question;
  const answers = randomizeAnswersEnabled ? randomizedOptions : options;
  if (question.type === 'singleSelectImage') {
    return answers.map((answer: any) => ({
      id: answer.id,
      image: answer.image,
      altText: answer.altText,
      selected: question.response.some((a: any) => a === answer.id)
    }));
  }
  return answers.map((answer: any) => ({
    id: answer.id,
    isCorrect: answer.isCorrect,
    text: answer.text,
    selected: question.response.some((a: any) => a === answer.id)
  }));
};

export const getScore = (state: RootAppState, question: any) => {
  if (question.type === 'singleSelectImage') {
    if (!question.response.includes(question.correctAnswerId)) {
      return 0;
    }
  } else {
    for (let i = 0; i < question.answers.length; i++) {
      const answer = question.answers[i];
      if (answer.isCorrect && !question.response.includes(answer.id)) {
        return 0;
      }
      if (!answer.isCorrect && question.response.includes(answer.id)) {
        return 0;
      }
    }
  }

  return 100;
};

export const isAttempted = (question: any) => !!question.response?.length;
