import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import pdfjs from '@bundled-es-modules/pdfjs-dist';
import {
  CloseBtnIcon,
  CloseBtnText,
  CloseBtnWrapper,
  DocumentContainer,
  Document
} from './PdfDocument.styled';
import { LTR } from 'constants/language';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type PdfDocumentProps = {
  url: string;
  toggle(any: boolean): any;
};

export const PdfDocument = (props: PdfDocumentProps) => {
  const onClick = () => {
    props.toggle(false);
  };

  const [pdfDocument, setPdfDocument] = useState();
  const rootDomElement: any = document.getElementById('root');

  useEffect(() => {
    pdfjs
      .getDocument({ url: props.url })
      .promise.then((loadedPdfDocument: any) => setPdfDocument(loadedPdfDocument));
    document.getElementById('the-canvas')?.parentElement?.focus();
  }, [props.url]);

  const renderPdf = async () => {
    const pages: any = [];
    for (let num = 1; num <= pdfDocument?.numPages; num++) {
      const page = await pdfDocument.getPage(num);
      pages.push(renderPage(page, num));
    }
    return Promise.all(pages);
  };

  (async () => {
    await renderPdf();
  })();

  const canvasContainer = document.getElementById('the-canvas');

  async function renderPage(page: any, pageNumber: number) {
    const viewport = page.getViewport({ scale: 3 });
    let canvas: any = document.getElementById(`canvas-${pageNumber}`);

    const canvasWrapper =
      document.getElementById(`canvas-wrapper-${pageNumber}`) || document.createElement('div');

    if (!canvas) {
      canvasWrapper.setAttribute('id', `canvas-wrapper-${pageNumber}`);
      canvasWrapper.classList.add('canvas-wrapper');
      canvas = document.createElement('canvas');
      canvas.setAttribute('id', `canvas-${pageNumber}`);
    }

    canvas.style.height = window.innerHeight;
    canvas.style.width = `${window.innerWidth * 0.66}px`;

    const ctx = canvas.getContext('2d');
    const renderContext = {
      canvasContext: ctx,
      viewport
    };
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    canvasWrapper.appendChild(canvas);
    if (canvasContainer) {
      canvasContainer.appendChild(canvasWrapper);
    }
    return page.render(renderContext);
  }

  const popupPdf = (
    <DocumentContainer>
      <Document dir={LTR}>
        <div id="the-canvas"></div>
      </Document>
      <CloseBtnWrapper tabIndex={0} ariaLabel={'esc'} onClick={onClick}>
        <CloseBtnIcon size={30} name="close-popup" />
        <CloseBtnText size={9}> Esc </CloseBtnText>
      </CloseBtnWrapper>
    </DocumentContainer>
  );

  return ReactDOM.createPortal(popupPdf, rootDomElement);
};

export default PdfDocument;
