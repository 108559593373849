export const ICON = {
  SHAPES: {
    ROUND_FULL_COLOR: 'round-fullColor',
    ROUND_OUTLINED: 'round-outlined',
    ROUND_SHADED: 'round-shaded'
  }
} as const;

export const OPACITY = {
  PERCENT_30: '0.3',
  PERCENT_100: '1.0',
  PERCENT_8: '0.08'
} as const;

export const BUTTON = {
  LAYOUT: {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    SIMPLE: 'simple'
  },
  TYPE: {
    SUBMIT: 'submit',
    BUTTON: 'button',
    RESET: 'reset'
  }
} as const;

export const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
} as const;

export const TOC = { EXPANDED_WIDTH: 380, COLLAPSED_WIDTH: 56, TAIL_WIDTH: 30 };

export const Z_INDEX = {
  SIDEBAR: 10,
  TOC: 200,
  COLLAPSED_TOC: 210,
  TOC_TAIL: 220,
  BLOCKOUT: 100
};

export const LINK = {
  LAYOUT: {
    DEFAULT: 'default',
    FORM: 'form'
  }
};

export const INPUT_TYPE = {
  TEXT: 'text',
  PASSWORD: 'password',
  EMAIL: 'email'
} as const;

export const TOOLTIP = {
  CORRECT: 'correct',
  INCORRECT: 'incorrect'
} as const;

export const SWITCH_BUTTON_LAYOUT = {
  IMAGE_ANSWER: 'image-answer',
  WITH_TEXT: 'with-text',
  CHECKLIST: 'checklist'
};
export enum PAGE_NAME {
  INNER_PAGE = 'INNER_PAGE',
  COVER_PAGE = 'COVER_PAGE'
}
export type FONT_SIZE_PAGE = keyof typeof PAGE_NAME;
export const FONT_SIZE = {
  SEVEN: 7,
  COVER_PAGE: {
    SYSTEM: {
      H1: 16,
      H2: 14,
      H3: 12
    },
    CONTENT: {
      H1: 48,
      H2: 26,
      H3: 24,
      H4: 22,
      P: 18,
      MAX: {
        DESKTOP: 90,
        MOBILE: 48
      }
    }
  },
  INNER_PAGE: {
    SYSTEM: {
      H1: 18,
      H2: 14,
      H3: 12,
      BUTTON: 14
    },
    CONTENT: {
      MAX: {
        DESKTOP: 100,
        MOBILE: 48
      }
    }
  }
};
export const FONT_SIZES_LIST = [
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  18,
  20,
  22,
  24,
  25,
  26,
  28,
  30,
  31,
  32,
  35,
  36,
  40,
  48,
  64,
  90
];
