import media from 'components/mixins/media';
import { fontSizeAdjusted } from 'components/mixins/typography';
import {
  ACCESSIBILITY_BUTTON_TEXT_MARGINS,
  ACCESSIBILITY_BUTTON_SIZES,
  FONT_SIZE_BUTTON_SIZES,
  FONT_SIZE_ADJUSTMENT_OPTIONS,
  FONT_ADJUSTMENT_ICON_SIZES,
  FONT_ADJUSTMENT_BAR_SIZES
} from 'constants/accessibility';
import { FONT_SIZE, PAGE_NAME } from 'constants/components';
import { AccessibilityState, FONT_SIZE_ACTION } from 'store/accessibility/types';
import styled, { css } from 'styled-components';
import { transparentize } from 'utils/color';

const FONT_SIZE_ACTION_KEY = 'font-size-action';

export const ItemActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ADJUSTMENT_DISABLED = {
  [FONT_SIZE_ACTION.INCREASE]: FONT_SIZE_ADJUSTMENT_OPTIONS.HUGE,
  [FONT_SIZE_ACTION.DECREASE]: FONT_SIZE_ADJUSTMENT_OPTIONS.DEFAULT
};

const getButtonOpacity = (
  { fontSize, isContrastTheme }: AccessibilityState,
  action: FONT_SIZE_ACTION
) => {
  const contrastModeOpacity = isContrastTheme ? 0.4 : 0.6;
  return ADJUSTMENT_DISABLED[action] === fontSize ? contrastModeOpacity : 1;
};

type AccessibilityContainerProps = {
  theme: any;
  visible: boolean;
};

export const AccessibilityContainer = styled.div.attrs(() => ({
  'data-test': 'accessibility-options-container'
}))`
  font-family: ${props => props.theme.fonts.mainFont.fontFamily};
  ${fontSizeAdjusted(FONT_SIZE.COVER_PAGE.SYSTEM.H2, { page: PAGE_NAME.COVER_PAGE })};

  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  overflow: hidden;

  margin: ${(props: AccessibilityContainerProps) => (props.visible ? '24px 18px' : '0')};
  padding-top: 1px;
  height: ${(props: AccessibilityContainerProps) => (props.visible ? 'fit-content' : '0px')};
  opacity: ${(props: AccessibilityContainerProps) => (props.visible ? '1' : '0')};

  transition: all 0.1s ease-out;
  transition-property: height, opacity;
`;

type AccessibilityDescriptionProps = {
  theme: any;
  accessibility: AccessibilityState;
};

export const AccessibilityDescription = styled('div').attrs(() => ({
  'data-test': 'accessibility-description'
}))<AccessibilityDescriptionProps>`
  ${fontSizeAdjusted(FONT_SIZE.COVER_PAGE.SYSTEM.H3, { page: PAGE_NAME.COVER_PAGE })};
  color: ${props => props.theme.colors.textColor};
  max-width: 100%;
  margin-bottom: 11.5px;
  #accessibility-description-link {
    font-family: ${props => props.theme.fonts.mainFont.fontFamily};
    color: ${props => props.theme.colors.mainColor};
    ${fontSizeAdjusted(FONT_SIZE.COVER_PAGE.SYSTEM.H3, { page: PAGE_NAME.COVER_PAGE })};
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    text-decoration: underline;
  }
`;

type AccessibilityOptionContainerProps = {
  theme: any;
  visible?: boolean;
  enabled: boolean;
  fullWidth?: boolean;
  position?: string;
  'data-test'?: string;
  accessibility: AccessibilityState;
};

export const AccessibilityOptionContainer = styled.button.attrs(
  (props: AccessibilityOptionContainerProps) => ({
    'data-test': props['data-test'],
    'data-enabled': props.enabled
  })
)`
  ${fontSizeAdjusted(FONT_SIZE.COVER_PAGE.SYSTEM.H2, { page: PAGE_NAME.COVER_PAGE })};
  display: ${props => (props.visible ? 'block' : 'none')};
  height: ${(props: AccessibilityOptionContainerProps) =>
    ACCESSIBILITY_BUTTON_SIZES.FULL_SIZE[props.accessibility.fontSize].H}px;
  width: ${(props: AccessibilityOptionContainerProps) =>
    props.fullWidth
      ? ACCESSIBILITY_BUTTON_SIZES.FULL_SIZE[props.accessibility.fontSize].W
      : ACCESSIBILITY_BUTTON_SIZES.HALF_SIZE[props.accessibility.fontSize].W}px;
  padding: 0;
  margin-bottom: 1rem;
  border: none;
  border-radius: 8px;

  color: ${props =>
    props.enabled ? props.theme.colors.contentBodyColor : props.theme?.colors?.textColor};
  background-color: ${props =>
    props.enabled
      ? transparentize(props.theme?.colors?.ctaButtonColor, 1)
      : transparentize(props.theme?.colors?.textColor, 0.04)};

  &:hover {
    ${props =>
      !props.enabled &&
      `background-color: ${transparentize(props.theme?.colors?.textColor, 0.09)};`}
    cursor: pointer;
  }
  ${media.mobile`
      ${css`
        ${(props: AccessibilityOptionContainerProps) =>
          !props.accessibility.fontSize && `width: 260px;`}
      `}
  `}
`;

type AccessibilityOptionProps = {
  'data-test'?: string;
};

export const AccessibilityOption = styled.div.attrs((props: AccessibilityOptionProps) => ({
  'data-test': props['data-test']
}))`
  margin: auto;
  width: fit-content;
`;

type AccessibilityOptionTextProps = {
  theme: any;
  accessibility: AccessibilityState;
};

export const AccessibilityOptionText = styled.div.attrs(() => ({
  'data-test': 'accessibility-option-text'
}))<AccessibilityOptionTextProps>`
  font-family: ${props => props.theme.fonts.mainFont.fontFamily};
  display: block;
  padding: ${props => ACCESSIBILITY_BUTTON_TEXT_MARGINS.HALF_SIZE[props.accessibility.fontSize]}px 0
    0 0;
`;

export const FontSizeAdjustmentOptionText = styled.div.attrs(() => ({
  'data-test': 'accessibility-option-text'
}))<AccessibilityOptionTextProps>`
  font-family: ${props => props.theme.fonts.mainFont.fontFamily};
  display: inline-block;
  padding: 0 0 0 6px;
  ${fontSizeAdjusted(14, { property: 'height' })}
`;

type FontSizeAdjustmentContainerProps = {
  theme: any;
  accessibility: AccessibilityState;
};

export const FontSizeAdjustmentContainer = styled.div.attrs(() => ({}))<
  FontSizeAdjustmentContainerProps
>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;

  height: ${props => FONT_SIZE_BUTTON_SIZES[props.accessibility.fontSize]}px;
  max-width: 225px;
  margin-top: ${props =>
    ACCESSIBILITY_BUTTON_TEXT_MARGINS.FULL_SIZE[props.accessibility.fontSize]}px;
  border-radius: 16px;

  ${fontSizeAdjusted(12)}
  font-family: ${props => props.theme.fonts.mainFont.fontFamily};
  background-color: ${props => transparentize(props.theme?.colors?.textColor, 0.09)}; 
  width: ${props => FONT_ADJUSTMENT_BAR_SIZES[props.accessibility.fontSize]}px;
`;

type FontSizeAdjustmentButtonProps = {
  theme: any;
  accessibility: AccessibilityState;
  [FONT_SIZE_ACTION_KEY]: FONT_SIZE_ACTION;
};

export const FontSizeAdjustmentButton = styled.button.attrs((props: any) => ({
  'data-test': `font-size-${props[FONT_SIZE_ACTION_KEY]}-button`
}))<FontSizeAdjustmentButtonProps>`
  height: ${props => FONT_SIZE_BUTTON_SIZES[props.accessibility.fontSize]}px;
  width: ${props => FONT_SIZE_BUTTON_SIZES[props.accessibility.fontSize]}px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 100%;

  ${fontSizeAdjusted(20)};
  color: ${props => props.theme.colors.contentBodyColor};
  background-color: ${props =>
    transparentize(
      props.accessibility.isContrastTheme
        ? props.theme.colors.mainColor
        : props.theme.colors.textColor,
      getButtonOpacity(props.accessibility, props[FONT_SIZE_ACTION_KEY]),
      {
        solidColor: true
      }
    )};

  &:hover {
    cursor: pointer;
  }
  span {
    display: block;
    ${props =>
      props[FONT_SIZE_ACTION_KEY] === FONT_SIZE_ACTION.DECREASE &&
      `font-size: ${FONT_ADJUSTMENT_ICON_SIZES.DECREASE[props.accessibility.fontSize]}px`};
  }
  @media (-webkit-min-device-pixel-ratio: 1.25) and (-webkit-max-device-pixel-ratio: 1.25) {
    span {
      ${props => props[FONT_SIZE_ACTION_KEY] === FONT_SIZE_ACTION.DECREASE && `margin-left: -1px;`}
    }
  }
`;
