import { FONT_SIZE_ADJUSTMENT_OPTIONS } from 'constants/accessibility';
import initialState from 'store/initialState';
import { AccessibilityActionTypes, AccessibilityState, ActionTypes } from './types';

export default function accessibilityReducer(
  state: AccessibilityState = initialState.accessibility,
  action: AccessibilityActionTypes
): AccessibilityState {
  const fontSize = action.payload?.accessibility?.fontSize || FONT_SIZE_ADJUSTMENT_OPTIONS.DEFAULT;
  switch (action.type) {
    case ActionTypes.UPDATE_ACCESSIBILITY_SETTINGS:
    case ActionTypes.RESTORE_ACCESSIBILITY_SETTINGS:
      return {
        ...state,
        ...action.payload.accessibility,
        fontSize: typeof fontSize !== 'number' ? FONT_SIZE_ADJUSTMENT_OPTIONS[fontSize] : fontSize
      };
    default:
      return state;
  }
}
