import { ProgressStatus } from 'constants/progressStatus';
import { AccessibilityState } from 'store/accessibility/types';

export const isPassed = (status: number) => status === ProgressStatus.PASSED;

export const isFailed = (status: number) => status === ProgressStatus.FAILED;

export const isInProgress = (status: number) => status === ProgressStatus.IN_PROGRESS;

export const isAccessibilityEnabled = (accessibilitySettings: AccessibilityState) => {
  const accessibilityOptions = Object.values(accessibilitySettings);
  return accessibilityOptions.some(v => !!v);
};
export const accessibilityModeEnabled = (
  prevAccessibilitySettings: AccessibilityState,
  accessibilitySettings: AccessibilityState
) => {
  const wasAccessibilityEnabled = isAccessibilityEnabled(prevAccessibilitySettings);
  return !wasAccessibilityEnabled && isAccessibilityEnabled(accessibilitySettings);
};
