export type Context = {
  type?: string;
  property?: string;
  title?: string;
  id?: string;
};

export type CreatedBy = {
  email: string;
  isAnonymous?: boolean;
  fullName: string;
  firstName: string;
  lastName: string;
  isReviewer?: boolean;
  avatarUrl?: string;
};

export type UserMetaBy = {
  email: string;
  fullName: string;
  avatarUrl?: string;
  isReviewer?: boolean;
};

export type Reply = {
  id: string;
  text: string;
  createdOn: string;
  createdBy: CreatedBy;
  isDeleted?: boolean;
  deletedBy?: UserMetaBy;
  deletedSeenEmails?: string[];
};

export type Comment = {
  id: string;
  text: string;
  mentionedEmails?: string[];
  context?: Context;
  courseId: string;
  metadata?: object;
  createdOn: string;
  createdBy: CreatedBy;
  replies: Reply[];
  isDeleted?: boolean;
  isResolved?: boolean;
  resolvedBy?: UserMetaBy;
  deletedBy?: UserMetaBy;
  resolvedSeenEmails?: string[];
  deletedSeenEmails?: string[];
};

export type CommentResolveStatus = {
  id: string;
  context: Context;
  resolvedBy?: UserMetaBy;
};

export type CommentDeleteStatus = {
  id: string;
  context: Context;
  isResolved: boolean;
  deletedBy: UserMetaBy;
};

export type ReplyAddRestore = {
  commentId: string;
  context: Context;
  reply: Reply;
};

export type ReplyDelete = {
  commentId: string;
  context: Context;
  replyId: string;
  deletedBy: UserMetaBy;
};

export type CommentAck = {
  id: string;
  context: Context;
  email: string;
};

export type ReplyAck = {
  commentId: string;
  replyId: string;
  context: Context;
  email: string;
};

export enum ActionTypes {
  COMMENTS_LIST = 'COMMENTS_LIST',
  COMMENT_ADDED = 'COMMENT_ADDED',
  COMMENT_RESOLVED = 'COMMENT_RESOLVED',
  COMMENT_UNRESOLVED = 'COMMENT_UNRESOLVED',
  COMMENT_DELETED = 'COMMENT_DELETED',
  COMMENT_RESTORED = 'COMMENT_RESTORED',
  COMMENT_REPLY_ADDED = 'COMMENT_REPLY_ADDED',
  COMMENT_REPLY_DELETED = 'COMMENT_REPLY_DELETED',
  COMMENT_REPLY_RESTORED = 'COMMENT_REPLY_RESTORED',
  COMMENT_DELETED_ACK = 'COMMENT_DELETED_ACK',
  COMMENT_RESOLVED_ACK = 'COMMENT_RESOLVED_ACK',
  COMMENT_REPLY_DELETED_ACK = 'COMMENT_REPLY_DELETED_ACK'
}

interface ListComments {
  type: ActionTypes.COMMENTS_LIST;
  payload: {
    comments: Comment[];
  };
}

interface AddComment {
  type: ActionTypes.COMMENT_ADDED;
  payload: {
    comment: Comment;
  };
}

interface ResolveComment {
  type: ActionTypes.COMMENT_RESOLVED;
  payload: CommentResolveStatus;
}

interface UnResolveComment {
  type: ActionTypes.COMMENT_UNRESOLVED;
  payload: CommentResolveStatus;
}

interface DeleteComment {
  type: ActionTypes.COMMENT_DELETED;
  payload: CommentDeleteStatus;
}

interface RestoreComment {
  type: ActionTypes.COMMENT_RESTORED;
  payload: Comment;
}

interface AddReply {
  type: ActionTypes.COMMENT_REPLY_ADDED;
  payload: ReplyAddRestore;
}

interface DeleteReply {
  type: ActionTypes.COMMENT_REPLY_DELETED;
  payload: ReplyDelete;
}

interface RestoreReply {
  type: ActionTypes.COMMENT_REPLY_RESTORED;
  payload: ReplyAddRestore;
}

interface AckCommentResolve {
  type: ActionTypes.COMMENT_RESOLVED_ACK;
  payload: CommentAck;
}

interface AckCommentDelete {
  type: ActionTypes.COMMENT_DELETED_ACK;
  payload: CommentAck;
}

interface AckReplyDelete {
  type: ActionTypes.COMMENT_REPLY_DELETED_ACK;
  payload: ReplyAck;
}

export type CommentActionTypes =
  | ListComments
  | AddComment
  | ResolveComment
  | UnResolveComment
  | DeleteComment
  | RestoreComment
  | AddReply
  | DeleteReply
  | RestoreReply
  | AckCommentResolve
  | AckCommentDelete
  | AckReplyDelete;

export type CommentsState = Comment[];
