import { isPreviewMode, analyticsWriteKey } from 'store/settings/selectors';
import { getResourceId, getResourceType } from 'store/resource/selectors';
import resourcesMapper from 'core/mappers/resource';
import eventEmitter, { events } from 'core/events/eventEmitter';
import { ThunkResult } from '../types';
import { ActionTypes } from './types';
import * as contentsActions from '../contents/actions';
import { RESOURCE_PATH } from 'constants/navigation';
import { getUser } from 'store/user/selectors';
import Analytics from 'core/analytics/Analytics';
import analyticsEvents, { ResourceTypesProperty } from 'core/analytics/events';
import progressStorage from 'core/progressStorage';
import { updateAccessibilitySettings } from 'store/accessibility/actions';

export const load = (data: any): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  dispatch({ type: ActionTypes.RESOURCE_DATA_LOADING_STARTED });
  try {
    const { resource, resourceContents } = resourcesMapper.map(data);
    dispatch(contentsActions.contentsLoaded(resourceContents));
    dispatch({ type: ActionTypes.RESOURCE_DATA_LOADED, payload: resource });
    eventEmitter.emit(events.APP_INITIALIZED, {
      state: getState(),
      isScormMode: false
    });
  } catch (e) {
    dispatch({ type: ActionTypes.RESOURCE_DATA_LOADING_FAILED, reason: e });
    throw e;
  }
};

export const launch = (): ThunkResult<Promise<string>> => async (dispatch, getState) => {
  if (isPreviewMode(getState())) {
    return RESOURCE_PATH;
  }
  await progressStorage.restoreProgress();

  dispatch(
    updateAccessibilitySettings(
      await progressStorage.mergeAccessibilitySettings(
        await progressStorage.loadLocalAccessibilitySettings()
      )
    )
  );

  await eventEmitter.emit(events.USER_AUTHENTICATED, getUser(getState()));

  const resourceId = getResourceId(getState());
  const resourceType = getResourceType(getState());
  const writeKey = analyticsWriteKey(getState());
  await Analytics.init(writeKey);
  Analytics.trackEvent(analyticsEvents.OPEN_RESOURCE, null, {
    resourceId,
    resourceType: ResourceTypesProperty[resourceType]
  });

  return RESOURCE_PATH;
};
