import { stringify, parse } from 'zipson';

export const compress = (data: any) => stringify(data);
export const decompress = (data: any) => parse(data);

export const hashData = (dataJson: any) => {
  try {
    const data = JSON.stringify(dataJson);
    return Array.from(data).reduce(
      (hash: any, char: any) => 0 | (31 * hash + char.charCodeAt(0)),
      0
    );
  } catch (e) {
    console.error(e);
    return null;
  }
};
