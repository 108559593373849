import { events } from 'core/events/eventEmitter';
import BaseEventHandler from 'core/events/eventHandler';
import { isAppError } from 'store/app/selectors';
import progressStorage from './progressStorage';

export default class EventHandler extends BaseEventHandler {
  handlers: any[];

  constructor() {
    super();
    this.handlers = [
      { event: events.APP_INITIALIZED, instance: this.appInitialize.bind(this) },
      { event: events.APP_NAVIGATED, instance: this.appNavigated.bind(this) },

      { event: events.COURSE_LAUNCHED, instance: this.courseLaunched.bind(this) },
      {
        event: events.COURSE_QUESTION_POOL_SELECTED,
        instance: this.courseQuestionPoolSelected.bind(this)
      },
      {
        event: events.COURSE_RANDOMIZED_OPTIONS_SAVED,
        instance: this.courseRandomizedOptionsSaved.bind(this)
      },
      {
        event: events.COURSE_PROGRESSED,
        instance: this.courseProgressed.bind(this)
      },
      {
        event: events.COURSE_PROGRESS_RESTORE_FAILED,
        instance: this.courseProgressedRestoreFailed.bind(this)
      },
      {
        event: events.COURSE_LAUNCH_FAILED,
        instance: this.courseProgressedRestoreFailed.bind(this)
      },
      {
        event: events.COURSE_ATTEMPT_STARTED,
        instance: this.courseAttemptStarted.bind(this)
      },
      {
        event: events.QUESTION_ANSWERED,
        instance: this.questionsSubmit.bind(this)
      },
      {
        event: events.COURSE_CHECKSUM_UPDATED,
        instance: this.updateCourseChecksum.bind(this)
      },
      {
        event: events.SUBMIT_ONCE_QUESTION_ANSWERED,
        instance: this.updateQuestionProgress.bind(this)
      },
      {
        event: events.SUBMIT_ONCE_QUESTION_SUBMITTED,
        instance: this.updateCourseProgress.bind(this)
      },
      {
        event: events.COURSE_UPDATE_ATTEMPTS,
        instance: this.updateCourseAttemptProgress.bind(this)
      },
      {
        event: events.INFORMATION_CONTENT_EXPERIENCED,
        instance: this.questionsSubmit.bind(this)
      },
      {
        event: events.RESET_QUESTION_PROGRESS,
        instance: this.removeQuestionProgress.bind(this)
      },
      {
        event: events.RESET_TIMER_PROGRESS,
        instance: this.resetTimerProgress.bind(this)
      },
      {
        event: events.COURSE_TIMER_PROGRESSED,
        instance: this.courseProgressed.bind(this)
      },
      {
        event: events.COURSE_SUBMIT,
        instance: this.questionsSubmit.bind(this)
      },
      {
        event: events.COURSE_URL_UPDATED,
        instance: this.updateUrl.bind(this)
      },

      { event: events.USER_AUTHENTICATED, instance: this.userAuthorized.bind(this) },
      { event: events.USER_LOGOUT, instance: this.logout.bind(this) },

      {
        event: events.UPDATE_CHECKLIST_PROGRESS,
        instance: this.updateChecklistProgress.bind(this)
      },
      {
        event: events.UPDATE_ACCESSIBILITY_SETTINGS,
        instance: this.updateAccessibilitySettings.bind(this)
      }
    ];
  }

  appInitialize({ state, isScormMode }: any) {
    progressStorage.initialize({ state, isScormMode });
  }

  appNavigated({ url, state }: { [key: string]: any }) {
    // fix for browser back button
    if (progressStorage.url === url || (url === '/' && !isAppError(state))) {
      return;
    }

    progressStorage.url = url;
    progressStorage.saveProgress(state);
  }

  courseLaunched(attemptId: any) {
    progressStorage.attemptId = attemptId;
  }

  courseQuestionPoolSelected({
    questionPoolIds,
    state,
    shouldSaveProgress
  }: {
    questionPoolIds: string[];
    state: any;
    shouldSaveProgress?: boolean;
  }) {
    progressStorage.questionPool = questionPoolIds;
    if (shouldSaveProgress) {
      progressStorage.saveProgress(state);
    }
  }

  courseProgressed(state: any) {
    progressStorage.saveProgress(state);
  }

  courseProgressedRestoreFailed() {
    progressStorage.courseProgressedRestoreFailed();
  }

  courseAttemptStarted(state: any) {
    progressStorage.saveProgress(state);
  }

  courseRandomizedOptionsSaved({ randomizedOptionsList }: { randomizedOptionsList: string[] }) {
    progressStorage.randomizedOptionsList = randomizedOptionsList;
  }

  questionsSubmit({ statementsData, state, isSubmitOnce = false }: any) {
    if (!isSubmitOnce) {
      statementsData.forEach(({ questionData }: { questionData: any }) => {
        if (questionData) {
          this.updateQuestionProgress(questionData);
        }
      });
    } else {
      const [questionsStatementsData] = statementsData;
      questionsStatementsData.forEach((questionData: any) => {
        this.updateQuestionProgress(questionData);
      });
    }

    this.courseProgressed(state);
  }

  updateQuestionProgress([question]: any) {
    progressStorage.updateQuestionProgress(question);
  }

  updateCourseProgress([allQuestionsSubmitted]: any) {
    progressStorage.updateCourseProgress(allQuestionsSubmitted);
  }

  updateCourseAttemptProgress(courseAttemptNumber: any) {
    progressStorage.updateCourseAttemptProgress(courseAttemptNumber);
  }

  updateCourseChecksum({ courseChecksum }: { courseChecksum: any }) {
    progressStorage.updateCourseChecksum(courseChecksum);
  }

  removeQuestionProgress(questionId: any) {
    progressStorage.removeQuestionProgress(questionId);
  }

  resetTimerProgress() {
    progressStorage.resetTimerProgress();
  }

  userAuthorized(user: any) {
    progressStorage.user = user;
  }

  updateChecklistProgress(data: any) {
    progressStorage.updateChecklistProgress({ id: data.id, isChecked: data.isChecked });
    progressStorage.saveProgress(data.state);
  }

  updateAccessibilitySettings(data: any) {
    progressStorage.updateAccessibilitySettings(data.accessibility, data.state);
    progressStorage.saveProgress(data.state);
  }

  async logout({ state }: any) {
    await progressStorage.logout();
    this.appInitialize({ state });
  }

  updateUrl(url: string) {
    progressStorage.url = url;
  }
}
