import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import progressStorage from 'core/progressStorage';
import { INDEX_PATH, LOGIN_PATH, SIGNUP_PATH } from 'constants/navigation';
import { RootAppState } from 'store/types';
import { isLoaderVisibility } from 'store/loader/selectors';
import { shouldSaveCrossDevice } from 'store/user/selectors';

type RouteWithRedirectProps = {
  component: any;
  shouldRedirect: boolean;
  isLoaderVisible?: boolean;
  history?: { [key: string]: any };
  exact: any;
  path: any;
  saveCrossDevice?: boolean;
};
export const RouteWithRedirect = ({
  component: Component,
  shouldRedirect,
  isLoaderVisible,
  saveCrossDevice,
  ...rest
}: RouteWithRedirectProps) => (
  <Route
    {...rest}
    render={props => {
      const routeTo = progressStorage.url || INDEX_PATH;
      const canRedirect = props.history.location.pathname !== routeTo;
      const isSignUpPage = props.history.location.pathname === SIGNUP_PATH;

      if (!saveCrossDevice && isSignUpPage) {
        return <Redirect to={LOGIN_PATH} />;
      }

      if (shouldRedirect && canRedirect) {
        return <Redirect to={routeTo} />;
      }

      if (!isLoaderVisible && canRedirect) {
        return <Component {...props} />;
      }
      return <></>;
    }}
  />
);

function mapStateToProps(state: RootAppState) {
  return {
    isLoaderVisible: isLoaderVisibility(state),
    saveCrossDevice: shouldSaveCrossDevice(state)
  };
}
export default connect(mapStateToProps)(RouteWithRedirect);
