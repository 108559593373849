import { fontSizeAdjusted } from 'components/mixins/typography';
import styled from 'styled-components';

type SkipToContentProps = {
  href: string;
  top: number;
  left: number;
  tabIndex: number;
};
export const SkipToContentElement = styled.a.attrs((props: SkipToContentProps) => ({
  href: props.href,
  'data-test': 'skip-to-content-link',
  tabIndex: props.tabIndex
}))<SkipToContentProps>`
  position: fixed;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  padding: 5px 15px;
  font-family: 'Qanelas-Soft', 'IowanOldSt-BT', 'PT Serif', serif;
  ${fontSizeAdjusted(18)};
  font-weight: 600;
  background: #fff;
  color: #000;
  border: solid 1px #000;
  border-radius: 5px;
  text-decoration: none;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  z-index: 1999;

  &:focus {
    clip: auto;
    height: auto;
    width: auto;
    outline: none;
  }
`;
