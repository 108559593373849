import contentLoader from 'core/http/contentLoader';
import chunk from 'lodash.chunk';
import { ActionTypes, ContentState } from './types';
import { ThunkResult } from '../types';
const CONTENT_LOAD_BATCH_SIZE = 100;

export const contentsLoaded = (contents: any): ThunkResult => async dispatch => {
  dispatch({ type: ActionTypes.CONTENTS_LOADED, payload: contents });
  await loadAll(contents, dispatch);
};

const loadContent = async (content: ContentState, dispatch: any) => {
  if (content.value) {
    return;
  }
  try {
    const value = await contentLoader.loadStaticHtmlContent(content.url);
    dispatch({ type: ActionTypes.CONTENT_LOADED, payload: { contentId: content.id, value } });
  } catch (e) {
    dispatch({ type: ActionTypes.CONTENT_LOADING_FAILED, payload: { contentId: content.id } });
  }
};

export const loadContents = (contents: any): ThunkResult => async dispatch => {
  const promises: any[] = [];
  contents.forEach((content: ContentState) => {
    promises.push(loadContent(content, dispatch));
  });

  return Promise.all(promises);
};

const getContent = async (content: any, dispatch: any) => {
  const { value = '', id, url } = content;
  if (value) {
    return value;
  }

  let contentValue = '';
  try {
    contentValue = await contentLoader.loadStaticHtmlContent(url);
  } catch (e) {
    dispatch({ type: ActionTypes.CONTENT_LOADING_FAILED, payload: { contentId: id } });
  }
  return { id, value: contentValue };
};

async function loadAll(contents: any, dispatch: any) {
  if (!contents) {
    return;
  }
  const contentChunks = chunk(Object.values(contents), CONTENT_LOAD_BATCH_SIZE);
  for (const contentChunk of contentChunks) {
    const promiseCollection = [];
    for (const content of contentChunk) {
      promiseCollection.push(getContent(content, dispatch));
    }
    await Promise.all(promiseCollection).then(contentValues => {
      contentValues.forEach(contentValue => {
        if (contentValue.value) {
          contents[contentValue.id].value = contentValue.value;
        }
      });
    });
  }
  dispatch({ type: ActionTypes.CONTENTS_LOADED, payload: { contents } });
}
