import { accessibilityOptionsList } from 'constants/accessibility';
import { localize } from 'core/localization';
import * as React from 'react';
import { AccessibilityOptionDetails } from 'store/accessibility/types';

import {
  DescriptionContainer,
  DescriptionHeading,
  DescriptionItem,
  DescriptionItemsContainer,
  ItemDescription,
  ItemHead,
  ItemHeadingText,
  ItemIcon
} from './AccessibilityDescription.styled';

type AccessibilityDescriptionProps = {};
type AccessibilityDescriptionState = {};

const getItem = (item: AccessibilityOptionDetails) => (
  <DescriptionItem>
    <ItemHead>
      <ItemIcon name={item.icon.name} size={item.icon.size}></ItemIcon>
      <ItemHeadingText>{localize(item.label)}</ItemHeadingText>
    </ItemHead>
    <ItemDescription>{localize(item.description)}</ItemDescription>
  </DescriptionItem>
);
export class AccessibilityDescription extends React.Component<
  AccessibilityDescriptionProps,
  AccessibilityDescriptionState
> {
  render() {
    return (
      <DescriptionContainer>
        <DescriptionItemsContainer>
          <DescriptionHeading>{localize(`[wcag description title]`)}</DescriptionHeading>
          {accessibilityOptionsList.map(item => item.visible && getItem(item))}
        </DescriptionItemsContainer>
      </DescriptionContainer>
    );
  }
}

export default AccessibilityDescription;
