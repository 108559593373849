import styled from 'styled-components';
import { Text } from 'components/common';
import media from 'components/mixins/media';
import { fontSizeAdjusted } from 'components/mixins/typography';

export const TextElement = styled(Text)`
  &&& {
    font-weight: 300;
    color: ${props => props.theme.colors.textColor};
    ${fontSizeAdjusted(20)};
    ${media.mobile`
    padding: 0 40px;
    text-align: center;
  `};
  }
`;

export const ButtonsWrapper = styled.div`
  display: inline-block;
  margin-top: 40px;

  & > button {
    width: 100%;
    display: block;
    margin: 0 0 20px;
  }
`;

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
`;
