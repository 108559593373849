import { AccessibilityOptionDetails } from 'store/accessibility/types';

export enum FONT_SIZE_ADJUSTMENT_OPTIONS {
  DEFAULT = 0,
  LARGE = 1,
  EXTRA_LARGE = 2,
  HUGE = 3
}

export const FONT_SIZE_NAMES = {
  [FONT_SIZE_ADJUSTMENT_OPTIONS.DEFAULT]: '[default]',
  [FONT_SIZE_ADJUSTMENT_OPTIONS.LARGE]: '[large]',
  [FONT_SIZE_ADJUSTMENT_OPTIONS.EXTRA_LARGE]: '[extra large]',
  [FONT_SIZE_ADJUSTMENT_OPTIONS.HUGE]: '[huge]'
};

export const USER_MENU_SIZES = {
  [FONT_SIZE_ADJUSTMENT_OPTIONS.DEFAULT]: 300,
  [FONT_SIZE_ADJUSTMENT_OPTIONS.LARGE]: 338,
  [FONT_SIZE_ADJUSTMENT_OPTIONS.EXTRA_LARGE]: 378,
  [FONT_SIZE_ADJUSTMENT_OPTIONS.HUGE]: 418
};

export const USER_MENU_MARGINS = {
  [FONT_SIZE_ADJUSTMENT_OPTIONS.DEFAULT]: 12,
  [FONT_SIZE_ADJUSTMENT_OPTIONS.LARGE]: 13,
  [FONT_SIZE_ADJUSTMENT_OPTIONS.EXTRA_LARGE]: 15,
  [FONT_SIZE_ADJUSTMENT_OPTIONS.HUGE]: 17
};

export const ACCESSIBILITY_BUTTON_SIZES = {
  HALF_SIZE: {
    [FONT_SIZE_ADJUSTMENT_OPTIONS.DEFAULT]: { W: 117, H: 85 },
    [FONT_SIZE_ADJUSTMENT_OPTIONS.LARGE]: { W: 135, H: 98 },
    [FONT_SIZE_ADJUSTMENT_OPTIONS.EXTRA_LARGE]: { W: 155, H: 111 },
    [FONT_SIZE_ADJUSTMENT_OPTIONS.HUGE]: { W: 174, H: 123 }
  },
  FULL_SIZE: {
    [FONT_SIZE_ADJUSTMENT_OPTIONS.DEFAULT]: { W: 252, H: 85 },
    [FONT_SIZE_ADJUSTMENT_OPTIONS.LARGE]: { W: 290, H: 98 },
    [FONT_SIZE_ADJUSTMENT_OPTIONS.EXTRA_LARGE]: { W: 330, H: 111 },
    [FONT_SIZE_ADJUSTMENT_OPTIONS.HUGE]: { W: 370, H: 123 }
  }
};

export const FONT_SIZE_BUTTON_SIZES = {
  [FONT_SIZE_ADJUSTMENT_OPTIONS.DEFAULT]: 26,
  [FONT_SIZE_ADJUSTMENT_OPTIONS.LARGE]: 28,
  [FONT_SIZE_ADJUSTMENT_OPTIONS.EXTRA_LARGE]: 30,
  [FONT_SIZE_ADJUSTMENT_OPTIONS.HUGE]: 32
};

export const ACCESSIBILITY_BUTTON_TEXT_MARGINS = {
  HALF_SIZE: {
    [FONT_SIZE_ADJUSTMENT_OPTIONS.DEFAULT]: 13,
    [FONT_SIZE_ADJUSTMENT_OPTIONS.LARGE]: 17,
    [FONT_SIZE_ADJUSTMENT_OPTIONS.EXTRA_LARGE]: 18,
    [FONT_SIZE_ADJUSTMENT_OPTIONS.HUGE]: 21
  },
  FULL_SIZE: {
    [FONT_SIZE_ADJUSTMENT_OPTIONS.DEFAULT]: 14,
    [FONT_SIZE_ADJUSTMENT_OPTIONS.LARGE]: 16,
    [FONT_SIZE_ADJUSTMENT_OPTIONS.EXTRA_LARGE]: 19,
    [FONT_SIZE_ADJUSTMENT_OPTIONS.HUGE]: 21
  }
};

export const FONT_ADJUSTMENT_ICON_SIZES = {
  INCREASE: {
    [FONT_SIZE_ADJUSTMENT_OPTIONS.DEFAULT]: 26,
    [FONT_SIZE_ADJUSTMENT_OPTIONS.LARGE]: 28,
    [FONT_SIZE_ADJUSTMENT_OPTIONS.EXTRA_LARGE]: 30,
    [FONT_SIZE_ADJUSTMENT_OPTIONS.HUGE]: 32
  },
  DECREASE: {
    [FONT_SIZE_ADJUSTMENT_OPTIONS.DEFAULT]: 26,
    [FONT_SIZE_ADJUSTMENT_OPTIONS.LARGE]: 28,
    [FONT_SIZE_ADJUSTMENT_OPTIONS.EXTRA_LARGE]: 30,
    [FONT_SIZE_ADJUSTMENT_OPTIONS.HUGE]: 32
  }
};

export const FONT_ADJUSTMENT_ICON_MARGINS = {
  DECREASE: {
    [FONT_SIZE_ADJUSTMENT_OPTIONS.DEFAULT]: 0,
    [FONT_SIZE_ADJUSTMENT_OPTIONS.LARGE]: -1,
    [FONT_SIZE_ADJUSTMENT_OPTIONS.EXTRA_LARGE]: -1,
    [FONT_SIZE_ADJUSTMENT_OPTIONS.HUGE]: 0
  }
};

export const FONT_ADJUSTMENT_BAR_SIZES = {
  [FONT_SIZE_ADJUSTMENT_OPTIONS.DEFAULT]: 172,
  [FONT_SIZE_ADJUSTMENT_OPTIONS.LARGE]: 187,
  [FONT_SIZE_ADJUSTMENT_OPTIONS.EXTRA_LARGE]: 212,
  [FONT_SIZE_ADJUSTMENT_OPTIONS.HUGE]: 225
};

export const USER_MENU_ICON_SIZES = {
  [FONT_SIZE_ADJUSTMENT_OPTIONS.DEFAULT]: 12,
  [FONT_SIZE_ADJUSTMENT_OPTIONS.LARGE]: 14,
  [FONT_SIZE_ADJUSTMENT_OPTIONS.EXTRA_LARGE]: 16,
  [FONT_SIZE_ADJUSTMENT_OPTIONS.HUGE]: 17,
  ACCESSIBILITY: {
    [FONT_SIZE_ADJUSTMENT_OPTIONS.DEFAULT]: 20,
    [FONT_SIZE_ADJUSTMENT_OPTIONS.LARGE]: 23,
    [FONT_SIZE_ADJUSTMENT_OPTIONS.EXTRA_LARGE]: 26,
    [FONT_SIZE_ADJUSTMENT_OPTIONS.HUGE]: 29
  }
};

export const BUTTON_HEIGHTS = {
  [FONT_SIZE_ADJUSTMENT_OPTIONS.DEFAULT]: 50,
  [FONT_SIZE_ADJUSTMENT_OPTIONS.LARGE]: 51,
  [FONT_SIZE_ADJUSTMENT_OPTIONS.EXTRA_LARGE]: 53,
  [FONT_SIZE_ADJUSTMENT_OPTIONS.HUGE]: 55
};

export const accessibilityOptionsList: Array<AccessibilityOptionDetails> = [
  {
    'data-test': 'contrast-theme-button',
    visible: true,
    fullWidth: true,
    key: 'isContrastTheme',
    description: '[contrast theme description]',
    label: `[contrast theme]`,
    icon: {
      name: 'contrast',
      size: 20
    }
  },
  {
    'data-test': 'readable-font-button',
    key: 'isReadableFont',
    description: `[readable font description]`,
    label: `[readable font]`,
    icon: {
      name: 'readable-font',
      size: 20
    }
  },
  {
    'data-test': 'font-size-adjustment-button',
    visible: true,
    key: 'fontSize',
    description: `[font size adjustment description]`,
    label: `[font size adjustment]`,
    fullWidth: true,
    showFontSizeAdjustment: true,
    icon: {
      name: 'font-size',
      size: 20
    }
  },
  {
    'data-test': 'dyslexia-font-button',
    key: 'isDyslexiaFont',
    description: `[dyslexia font description]`,
    label: `[dyslexia font]`,
    icon: {
      name: 'dyslexia',
      size: 20
    }
  },
  {
    'data-test': 'seizure-safe-button',
    key: 'isSeizureSafe',
    description: `[seizure safe description]`,
    label: `[seizure safe]`,
    icon: {
      name: 'seizure-safe',
      size: 20
    }
  }
];

export const DYSLEXIE_FONT_NAME = 'Dyslexie';
