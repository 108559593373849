import clonedeep from 'lodash.clonedeep';
import initialState from 'store/initialState';
import { getReviewerCommentIndex, sortComments } from 'utils/comments';
import { ActionTypes, Comment, CommentActionTypes } from './types';
import { DELETE, RESOLVE } from 'constants/comments';

export default function commentReducer(state = initialState.comments, action: CommentActionTypes) {
  switch (action.type) {
    case ActionTypes.COMMENTS_LIST: {
      return sortComments(action.payload.comments);
    }
    case ActionTypes.COMMENT_ADDED: {
      const commentFound = state.find(comment => comment.id === action.payload.comment.id);
      if (!commentFound) {
        return sortComments([...state, action.payload.comment]);
      }
      return state;
    }
    case ActionTypes.COMMENT_RESOLVED: {
      return updateComment(state, action.payload.id, {
        isResolved: true,
        resolvedBy: action.payload.resolvedBy
      });
    }
    case ActionTypes.COMMENT_UNRESOLVED: {
      return updateComment(state, action.payload.id, {
        isResolved: false,
        resolvedBy: null,
        resolvedSeenEmails: []
      });
    }
    case ActionTypes.COMMENT_DELETED: {
      return updateComment(state, action.payload.id, {
        isDeleted: true,
        deletedBy: action.payload.deletedBy
      });
    }
    case ActionTypes.COMMENT_RESTORED: {
      return updateComment(state, action.payload.id, {
        isDeleted: false,
        deletedBy: null,
        deletedSeenEmails: []
      });
    }
    case ActionTypes.COMMENT_REPLY_ADDED: {
      return [
        ...state.map(comment => {
          if (comment.id === action.payload.commentId) {
            const commentObj = clonedeep(comment);
            commentObj.replies.push(action.payload.reply);
            return commentObj;
          }
          return comment;
        })
      ];
    }
    case ActionTypes.COMMENT_REPLY_DELETED: {
      return updateReply(state, action.payload.commentId, action.payload.replyId, {
        isDeleted: true,
        deletedBy: action.payload.deletedBy
      });
    }
    case ActionTypes.COMMENT_REPLY_RESTORED: {
      return updateReply(state, action.payload.commentId, action.payload.reply.id, {
        isDeleted: false,
        deletedBy: null,
        deletedSeenEmails: []
      });
    }
    case ActionTypes.COMMENT_RESOLVED_ACK: {
      return ackComment(state, action.payload.id, action.payload.email, RESOLVE);
    }
    case ActionTypes.COMMENT_DELETED_ACK: {
      return ackComment(state, action.payload.id, action.payload.email, DELETE);
    }
    case ActionTypes.COMMENT_REPLY_DELETED_ACK: {
      return ackReply(
        state,
        action.payload.commentId,
        action.payload.replyId,
        action.payload.email,
        DELETE
      );
    }
    default:
      return state;
  }
}

function updateComment(
  comments: Comment[],
  commentId: string,
  updates: { [key: string]: any }
): Comment[] {
  const commentIndex = getReviewerCommentIndex(comments, commentId);
  if (commentIndex < 0) {
    return comments;
  }

  const newComments = [...comments];
  const commentObj = clonedeep(newComments[commentIndex]);
  for (const [key, value] of Object.entries(updates)) {
    (commentObj as any)[key] = value;
  }
  newComments[commentIndex] = commentObj;

  return newComments;
}

function updateReply(
  comments: Comment[],
  commentId: string,
  replyId: string,
  updates: { [key: string]: any }
): Comment[] {
  const commentIndex = getReviewerCommentIndex(comments, commentId);
  if (commentIndex < 0) {
    return comments;
  }

  const newComments = [...comments];
  const commentObj = clonedeep(newComments[commentIndex]);
  const replyIndex = commentObj.replies.findIndex(reply => reply.id === replyId);
  if (replyIndex < 0) {
    return comments;
  }

  const replyObj = commentObj.replies[replyIndex];

  for (const [key, value] of Object.entries(updates)) {
    (replyObj as any)[key] = value;
  }
  commentObj.replies[replyIndex] = replyObj;
  newComments[commentIndex] = commentObj;

  return newComments;
}

function ackComment(
  comments: Comment[],
  commentId: string,
  email: string,
  type: string
): Comment[] {
  const commentIndex = getReviewerCommentIndex(comments, commentId);
  if (commentIndex < 0) {
    return comments;
  }

  const newComments = [...comments];
  const commentObj = clonedeep(newComments[commentIndex]);
  if (type === DELETE) {
    (commentObj as any).deletedSeenEmails = (commentObj as any).deletedSeenEmails || [];
    (commentObj as any).deletedSeenEmails.push(email);
  } else if (type === RESOLVE) {
    (commentObj as any).resolvedSeenEmails = (commentObj as any).resolvedSeenEmails || [];
    (commentObj as any).resolvedSeenEmails.push(email);
  }
  newComments[commentIndex] = commentObj;

  return newComments;
}

function ackReply(
  comments: Comment[],
  commentId: string,
  replyId: string,
  email: string,
  type: string
): Comment[] {
  const commentIndex = getReviewerCommentIndex(comments, commentId);
  if (commentIndex < 0) {
    return comments;
  }

  const newComments = [...comments];
  const commentObj = clonedeep(newComments[commentIndex]);

  const replyIndex = commentObj.replies.findIndex(reply => reply.id === replyId);
  if (replyIndex < 0) {
    return comments;
  }

  const replyObj = commentObj.replies[replyIndex];

  if (type === DELETE) {
    (replyObj as any).deletedSeenEmails = (replyObj as any).deletedSeenEmails || [];
    (replyObj as any).deletedSeenEmails.push(email);
  } else if (type === RESOLVE) {
    (replyObj as any).resolvedSeenEmails = (replyObj as any).resolvedSeenEmails || [];
    (replyObj as any).resolvedSeenEmails.push(email);
  }
  commentObj.replies[replyIndex] = replyObj;
  newComments[commentIndex] = commentObj;

  return newComments;
}
