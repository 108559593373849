export const LOGIN_LINK_FETCHING_FAILED = 'loginLinkFetchingFailed';

export const REVIEW_USER_EMAIL = 'reviewerUsermail';
export const REVIEW_USER_NAME = 'reviewerUsername';
export const REVIEW_USER_TOKEN = 'token.review';
export const HINT_FOR_SPOT_COMMENT = 'hintForSpotComment';
export const COMMENT_SENDING_FAILED = 'commentSendingFailed';
export const REPLY_SENDING_FAILED = 'replySendingFailed';
export const COMMENT_DELETING_FAILED = 'commentDeletingFailed';
export const REPLY_DELETING_FAILED = 'replyDeletingFailed';
export const ACKNOWLEDGING_FAILED = 'acknowledgingFailed';
export const COMMENTS_LISTING_FAILED = 'commentsListingFailed';
export const USER_INFO_FETCHING_FAILED = 'userInfoFetchingFailed';
export const NOT_FOUND = 'notFound';
export const STATUS_OK = 'statusOk';
export const REFERRER_POLICY = 'no-referrer-when-downgrade';
export const REVIEW_TOKEN = 'reviewerToken';
export const REVIEW = 'Review';

export const HEADER_X_COURSE_ID = 'X-Course-Id';

export const REVIEW_COMMENT_PROPERTIES: { [key: string]: string } = {
  DEFAULT_TITLE: 'defaultTitle',
  TITLE: 'title',
  INTRODUCTION: 'introduction',
  LEARNING_CONTENT: 'learningContent',
  QUESTION: 'question',
  VOICE_OVER: 'voiceOver',
  CONTENT_ITEM: 'contentItem',
  TITLE_OF_THE_SECTION: 'titleOfTheSection'
};

export const REVIEW_COMMENT_TYPES: { [key: string]: string } = {
  QUESTION: 'question',
  INFORMATION_CONTENT: 'informationContent',
  COURSE: 'course',
  SECTION: 'section'
};

export enum LAYOUT {
  LOGIN_WITH_EMAIL = 'LOGIN_WITH_EMAIL',
  REGISTER_NEW_REVIEWER = 'REGISTER_NEW_REVIEWER',
  VERIFICATION_EMAIL_SENT = 'VERIFICATION_EMAIL_SENT',
  ADD_COMMENT = 'ADD_COMMENT',
  ADD_COMMENT_WITH_HISTORY = 'ADD_COMMENT_WITH_HISTORY'
}

export const SUBMIT_BUTTON_TEXT: { [key in LAYOUT]: string } = {
  [LAYOUT.LOGIN_WITH_EMAIL]: '[continue]',
  [LAYOUT.REGISTER_NEW_REVIEWER]: '[get login link]',
  [LAYOUT.VERIFICATION_EMAIL_SENT]: '[write a comment]',
  [LAYOUT.ADD_COMMENT]: '[post comment]',
  [LAYOUT.ADD_COMMENT_WITH_HISTORY]: '[post comment]'
};

export const ACKNOWLEDGED_STATUS = {
  DELETED: 'delete',
  RESOLVED: 'resolve'
};

export const REVIEW_COLLABORATION: { [key in string]: string } = {
  [LAYOUT.REGISTER_NEW_REVIEWER]: 'Get login link',
  [LAYOUT.VERIFICATION_EMAIL_SENT]: 'Write a comment',
  [LAYOUT.ADD_COMMENT]: 'Post comment',
  [LAYOUT.ADD_COMMENT_WITH_HISTORY]: 'Post comment',
  ADD_REPLY: 'Post reply'
};
