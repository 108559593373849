export const COURSE_LAUNCHED = 'course:launched';
export const COURSE_STARTED = 'course:started';
export const COURSE_FINISHED = 'course:finished';
export const COURSE_FINALIZED = 'course:finalized';
export const COURSE_EVALUATED = 'course:evaluated';
export const COURSE_COMPLETED = 'course:completed';

export const COURSE_PROGRESSED = 'course:progressed';
export const COURSE_PROGRESS_RESTORE_FAILED = 'course:progressedRestoreFailed';
export const COURSE_LAUNCH_FAILED = 'course:launchFailed';
export const COURSE_CHECKSUM_UPDATED = 'course:checksumUpdated';
export const COURSE_ATTEMPT_STARTED = 'course:attemptStarted';
export const COURSE_QUESTION_POOL_SELECTED = 'course:questionPoolSelected';
export const COURSE_RANDOMIZED_OPTIONS_SAVED = 'course:randomizedOptionsSaved';
export const COURSE_SUBMIT = 'course:submit';
export const COURSE_URL_UPDATED = 'course:urlUpdated';

export const QUESTION_ANSWERED = 'question:answered';
export const SUBMIT_ONCE_QUESTION_ANSWERED = 'question:submitOnceAnswered';

export const SUBMIT_ONCE_QUESTION_SUBMITTED = 'question:submitOnce';
export const COURSE_UPDATE_ATTEMPTS = 'question:submitOnceAttempted';
export const INFORMATION_CONTENT_EXPERIENCED = 'information-content:experienced';

export const APP_INITIALIZED = 'app:initialized';
export const APP_NAVIGATED = 'app:navigated';
export const APP_OFFLINE_OR_SERVER_ERROR = 'app:offline-or-server-error';
export const APP_LRS_MISCONFIGURATION_ERROR = 'app:lrsMisconfigurationError';
export const APP_ONLINE = 'app:online';

export const RESET_QUESTION_PROGRESS = 'progress:resetQuestionProgress';

export const USER_AUTHENTICATED = 'user:authenticated';
export const USER_LOGOUT = 'user:logout';

export const REVIEW_INITIALIZED = 'review:initialized';
export const COMMENT_SENT = 'review:comment-sent';
export const COMMENT_REPLY_SENT = 'review:comment-reply-sent';
export const COMMENT_DELETED = 'review:comment-deleted';
export const REPLY_DELETED = 'review:reply-deleted';
export const ACK_COMMENT_DELETED = 'review:ack-comment-deleted';
export const ACK_COMMENT_RESOLVED = 'review:ack-comment-resolved';
export const ACK_REPLY_DELETED = 'review:ack-reply-deleted';
export const ACK_REPLY_RESOLVED = 'review:ack-reply-resolved';
export const COMMENTS_LIST = 'review:comments-list';
export const GET_REVIEWER_INFO = 'review:get-user-info';
export const END_REVIEWER_SESSION = 'review:end-user-session';
export const GET_REVIEWER_INFO_FROM_TOKEN = 'review:get-user-info-from-token';
export const UPDATE_EMAIL_FOR_LOGIN_LINK = 'review:update-email-for-login-link';
export const GET_LOGIN_LINK = 'review:get-login-link';

export const RESET_TIMER_PROGRESS = 'progress:resetTimerProgress';
export const COURSE_TIMER_PROGRESSED = 'course:timerProgressed';

export const UPDATE_ACCESSIBILITY_SETTINGS = 'accessibility:updated';

/* Checklist */

export const UPDATE_CHECKLIST_PROGRESS = 'checklist:update';
