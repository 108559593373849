import { schema } from 'normalizr';
import { renameProperty, guid } from 'utils/object';
import { INFORMATION_CONTENT } from 'constants/questionTypes';
import { getInitialQuestionResponse } from 'store/initialState';
import content from './content';
import explanationBlock from './explanationBlock';

export type QuestionChildren = Array<object>;

export function transferPropBetweenObj(
  currentList: [{ [key: string]: any }],
  nameOfTargetProp: string,
  targetList: [{ [key: string]: any }]
) {
  if (currentList) {
    currentList.forEach((prop: any) => {
      if (prop[nameOfTargetProp] && prop[nameOfTargetProp].length) {
        prop[nameOfTargetProp].forEach((item: any) => {
          targetList.push({ id: item.id });
        });
      }
    });
  }
}

export function transferExplanationBlocks(
  answerList: [{ [key: string]: any }],
  targetList: [{ [key: string]: any }]
) {
  if (answerList) {
    answerList.forEach((answer: any) => {
      if (answer.explanationBlocks && answer.explanationBlocks.length) {
        answer.explanationBlocks.forEach((explanation: any) => {
          const entity: { [key: string]: any } = {
            id: explanation.id
          };
          if (explanation.children && explanation.children.length) {
            entity.children = explanation.children;
          }
          targetList.push(entity);
        });
      }
    });
  }
}

const question = new schema.Entity(
  'questions',
  {
    children: [content],
    instructions: [content],
    hints: [content],
    correctFeedback: [content],
    incorrectFeedback: [content],
    questionContent: [content],
    explanationBlocks: [explanationBlock]
  },
  {
    processStrategy: (value, parent) => {
      const result = {
        ...value,
        questionContent: [],
        children: [] as QuestionChildren,
        explanationBlocks: [],
        sectionId: parent.id,
        score: 0,
        hasBeenOpened: false,
        isAnswered: false,
        isAnsweredCorrectly: false,
        isAnswerChanged: false,
        isPreviousAnswerCorrect: false,
        response: getInitialQuestionResponse(value.type),
        affectsProgress: value.type !== INFORMATION_CONTENT
      };

      if (result.hasOwnProperty('isSurvey') && result.affectsProgress) {
        result.affectsProgress = !result.isSurvey;
      }

      if (result.hasContent) {
        result.questionContent.push({ id: guid() });
      }

      transferPropBetweenObj(result.learningContents, 'children', result.children);
      transferPropBetweenObj(result.questionInstructions, 'children', result.children);
      transferPropBetweenObj(result.questionCorrectFeedbacks, 'children', result.children);
      transferPropBetweenObj(result.questionIncorrectFeedbacks, 'children', result.children);

      transferExplanationBlocks(result.answers, result.explanationBlocks);

      renameProperty(result, 'questionInstructions', 'instructions');
      renameProperty(result, 'learningContents', 'hints');
      renameProperty(result, 'questionCorrectFeedbacks', 'correctFeedback');
      renameProperty(result, 'questionIncorrectFeedbacks', 'incorrectFeedback');
      delete result.hasCorrectFeedback;
      delete result.hasIncorrectFeedback;
      delete result.hasContent;
      return result;
    }
  }
);

export default question;
