import * as React from 'react';
import { localize } from 'core/localization';
import { Tooltip } from 'components/common';
import { HintWrapper, HintContainer, HintButton, HintElementsContainer } from './Hint.styled';

type HintProps = {
  children: any;
  isExpanded: boolean;
  hintElements: any;
  hintName: string;
  position: any;
  isRtl?: boolean;
};

type HintState = {
  isExpanded: boolean;
}

export class Hint extends React.PureComponent<HintProps, HintState> {
  constructor(props: HintProps) {
    super(props);
    this.state = {
      isExpanded: this.props.isExpanded
    };
  }

  static defaultProps = {
    children: null,
    isExpanded: true,
    hintElements: null,
    hintName: ''
  };

  componentWillMount() {
    const { hintName } = this.props;
    if (hintName) {
      this.setState({
        isExpanded: !localStorage.getItem(hintName) || false
      });
    }
  }

  componentDidUpdate(prevProps: HintProps) {
    const { isExpanded, hintName } = this.props;
    if (isExpanded !== prevProps.isExpanded) {
      this.setState({
        isExpanded
      });
    }
    this.hintShowOnce(hintName);
  }

  hintShowOnce = (hintName: any) => {
    if (hintName) {
      localStorage.setItem(hintName, true as any);
      this.setState({
        isExpanded: !localStorage.getItem(hintName)
      });
    }
  };


  closePopover = (event: any) => {
    event.preventDefault();
    const { isExpanded } = this.state;
    const { hintName } = this.props;
    this.setState({
      isExpanded: !isExpanded
    });

    this.hintShowOnce(hintName);
  };

  render() {
    const { isExpanded } = this.state;
    const { hintElements, children, position, isRtl } = this.props;
    return (
      <Tooltip
        trigger={'manual'}
        open={isExpanded}
        tooltipTheme={'light'}
        sticky={true}
        arrow={true}
        position={position}
        interactive
        isRtl={isRtl}
        html={
          <HintWrapper>
            <HintContainer>
              <HintElementsContainer>{hintElements}</HintElementsContainer>
              <HintButton onClick={this.closePopover}>{localize('[got it]')}</HintButton>
            </HintContainer>
          </HintWrapper>
        }
      >
        {children}
      </Tooltip>
    );
  }
}

export default Hint;
