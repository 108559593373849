import styled, { css } from 'styled-components';
import media from 'components/mixins/media';
import { Button, Icon, Text } from 'components/common';
import { TextElement } from 'components/common/Button/Button.styled';
import { fontSizeAdjusted, secondaryFont } from 'components/mixins/typography';
import { transparentize } from 'utils/color';
import buttonDefaultStyle from 'components/mixins/normalize';
import { defaultBoxShadowColor } from 'constants/common';
import { FONT_SIZE, PAGE_NAME } from 'constants/components';
import { USER_MENU_MARGINS, USER_MENU_SIZES } from 'constants/accessibility';
import { AccessibilityState } from 'store/accessibility/types';
import getCustomScrollbars from 'components/mixins/customScrollbars';

type IconContainerProps = {
  opacity?: string | number;
};

export const IconContainer = styled.div`
  display: flex;
  width: 15px;
  height: 100%;
  align-items: center;
  padding: 3px;
  opacity: ${(props: IconContainerProps) => props.opacity || 1};
  transition: opacity 0.2s linear;
`;

export const ToolTipContainer = styled.div`
  .tippy-popper {
    top: 68px !important;
  }
`;

export const UserDetailsContainer = styled.div.attrs({
  'data-test': 'app-menu-user-details-container'
})`
  text-align: left;
  padding: 16px;

  ${media.mobile`
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  `}
`;

export const NameText = styled.div.attrs({
  'data-test': 'app-menu-name-text'
})`
  color: ${props => props.theme.colors.mainColor};
  ${fontSizeAdjusted(FONT_SIZE.COVER_PAGE.SYSTEM.H1, { page: PAGE_NAME.COVER_PAGE })};
  font-family: ${props => props.theme.fonts.mainFont.fontFamily};
  font-weight: 600;
  ${media.mobile`
    width: 260px;
  `}
`;

export const EmailText = styled.div.attrs((props: { children: Element }) => ({
  'data-test': 'app-menu-email-text',
  'data-title': String(props.children)
}))`
  color: ${props => props.theme.colors.textColor};
  ${fontSizeAdjusted(FONT_SIZE.COVER_PAGE.SYSTEM.H2, { page: PAGE_NAME.COVER_PAGE })};
  font-family: ${props => props.theme.fonts.mainFont.fontFamily};
  opacity: ${props => props.theme.themeUtils.getOpacity(true, 0.7, { contrastValue: 1 })};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &[data-title]:hover::after {
    opacity: 1;
    transition: all 0.1s ease 0.2s;
    visibility: visible;
  }
  &[data-title]::after {
    content: attr(data-title);
    visibility: hidden;
    background-color: white;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 2px 6px;
    position: fixed;
    left: 10%;
    bottom: 86%;
    border: 1px solid black;
    font-weight: bold;
    font-size: 12px;
  }
  ${media.mobile`
    width: 260px;
  `}
`;

type ItemTextProps = {
  theme: any;
  accessibility: AccessibilityState;
};

export const ItemText = styled(Text)<ItemTextProps>`
  text-align: left;
  margin-left: ${props => USER_MENU_MARGINS[props.accessibility.fontSize]}px;
  color: ${props => props.theme.colors.textColor};
`;

type ListItemProps = {
  tabIndex: string;
};

export const ListItem = styled(Button).attrs((props: ListItemProps) => ({
  tabIndex: props.tabIndex
}))`
  ${buttonDefaultStyle};
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 16px;
  border-radius: 0;
  line-height: 0;
  cursor: pointer;
  margin: 1px 0;

  ${ItemText} {
    ${fontSizeAdjusted(FONT_SIZE.COVER_PAGE.SYSTEM.H2, { page: PAGE_NAME.COVER_PAGE })};
  }

  ${TextElement} {
    display: flex;
    align-items: center;
    font-weight: normal;
  }

  ${IconContainer} {
    width: max-content;
    padding: 1px;
    ${fontSizeAdjusted(FONT_SIZE.COVER_PAGE.SYSTEM.H2, { page: PAGE_NAME.COVER_PAGE })};
  }

  &:hover,
  &:focus {
    background-color: ${(props: any) => transparentize(props.theme.colors.textColor, 0.05)};
    ${IconContainer} {
      opacity: 1;
    }

    ${ItemText} {
      color: ${props => transparentize(props.theme.colors.textColor, 1)};
    }
  }
  &:focus {
    background-color: ${props => transparentize(props.theme.colors.textColor, 0.05)};
    outline: 1px solid black;
    ${IconContainer} {
      opacity: 1;
    }

    ${ItemText} {
      color: ${props => transparentize(props.theme.colors.textColor, 1)};
    }
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
  &:not(:hover) {
    background-color: ${(props: any) => props.theme.colors.contentBodyColor};
    ${IconContainer} {
      opacity: ${props => props.theme.themeUtils.getOpacity(true, 0.5, { contrastValue: 1 })};
    }
  }

  ${media.mobile`
      padding: 16px 0;
      justify-content: center;

      && > span {
        width: 260px;
      }
  `}
`;

type ActionListContainerProps = {
  theme: any;
  accessibility: AccessibilityState;
};

export const ActionListContainer = styled.div.attrs({
  'data-test': 'action-list-container'
})<ActionListContainerProps>`
  position: relative;
  width: ${props => USER_MENU_SIZES[props.accessibility.fontSize] - 14}px;
  max-width: 100vw;
  max-height: calc(100vh - 150px);
  border-radius: 2px;
  padding: 0;
  background-color: ${props => props.theme.colors.contentBodyColor};
  overflow: auto;

  ${css`
    ${getCustomScrollbars()}
  `}
  ${media.mobile`
    width: 100vw;  
  `}
`;

export const AvatarLetterElement = styled.span`
  font-size: 12px;
  ${secondaryFont};
  font-weight: bold;
  color: ${props => props.theme.colors.buttonTextColor};
`;

type AvatarLetterContainerProps = {
  theme?: any;
  backgroundColor?: string;
};

export const AvatarLetterContainer = styled.div<AvatarLetterContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  margin-right: 4px;
  background-color: ${(props: AvatarLetterContainerProps) =>
    props.backgroundColor || props.theme.colors.mainColor};
  border-radius: 20px;
`;

type AccessibilityEnabledIconProps = {
  theme: any;
  accessibility: AccessibilityState;
};
export const AccessibilityEnabledIcon = styled(Icon)<AccessibilityEnabledIconProps>`
  color: ${props => props.theme.colors.mainColor};
  background-color: ${props => props.theme.colors.contentBodyColor};
  position: absolute;
  top: 0;
  right: 20px;
  border-radius: 100%;

  margin: 0;
  padding: 1px;
`;

export const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ItemActionContainer = styled.div.attrs({
  'data-test': 'item-actions-container'
})`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: 0;
`;

type ExpandableWrapperProps = {
  ariaHidden?: any;
  tabIndex?: number;
  isContrastTheme?: boolean;
};

export const ExpandableWrapper = styled.button.attrs((props: ExpandableWrapperProps) => ({
  'aria-hidden': props.ariaHidden,
  'data-test': 'app-menu-dropdown-toggler',
  tabIndex: props.tabIndex
}))<ExpandableWrapperProps>`
  ${buttonDefaultStyle};
  display: flex;
  padding: 0;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.1s ease-out, focus 0s;

  &:hover,
  &:active {
    outline: none;
    ${IconContainer}:not(#accessibility-login-icon-container) {
      opacity: ${props => (props.isContrastTheme ? 1 : 0.7)};
    }
  }
  &:focus {
    outline: 1px solid black;
    background: none;
    ${IconContainer}:not(#accessibility-login-icon-container) {
      opacity: ${props => (props.isContrastTheme ? 1 : 0.7)};
    }
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
`;

type MenuContainerProps = {
  ariaHidden?: any;
};

export const MenuContainer = styled.div.attrs((props: MenuContainerProps) => ({
  'aria-hidden': props.ariaHidden
}))<MenuContainerProps>`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 3px;
  position: absolute;
  background-color: ${props => props.theme.colors.contentBodyColor};
  box-shadow: 0 2px 10px 0 ${transparentize(defaultBoxShadowColor, 0.1)};
  border-radius: 20px;
  top: 20px;
  right: 30px;
  z-index: 105;

  ${media.mobile`
    top: 10px;
    right: 10px;
  `};
  .tippy-popper {
    top: 22px;
  }
`;

type LineProps = {
  theme: any;
};

export const LineContainer = styled.div`
  padding: 0 18px;
`;

export const Line = styled.hr.attrs(() => ({}))<LineProps>`
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: ${(props: LineProps) => transparentize(props.theme.colors.textColor, 0.05)}};
`;
