import React, { Fragment, PureComponent } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { localize } from 'core/localization';
import { Button } from 'components/common';
import {
  TextElement,
  HeaderElement,
  Container,
  ContentWrapper,
  ButtonsWrapper,
  Figure,
  NoteWrapper,
  Note,
  CircleWrapper,
  ReconnectionWrapper,
  ReconnectedIcon
} from './Common.styled';
import { getColors, getFonts } from 'store/settings/selectors';
import CircleLoader from 'components/common/CircleLoader';
import LostConnection from 'assets/figures/LostConnection';
import { BUTTON, ICON, SIZES } from 'constants/components';
import { RootAppState } from 'store/types';
import * as popupActions from 'store/popup/actions';
import { getAccessibilitySettings } from 'store/accessibility/selectors';

type CommonAlertProps = {
  popupAction: { [key: string]: any };
  isConnectionError?: boolean;
  isOffline: boolean;
  colors: any;
  fonts: any;
  title: string;
  message: string;
  'data-test': string;
  accessibilitySettings: any;
};

export class CommonOverlay extends PureComponent<CommonAlertProps, {}> {
  static defaultProps = {
    isOffline: false
  };

  state = {
    closeCourse: false
  };

  closeCourse = () => {
    this.setState({
      closeCourse: true
    });
  };

  render() {
    const { isOffline, isConnectionError, title, message } = this.props;
    const { closeCourse } = this.state;

    return (
      <Fragment>
        <Container data-test={this.props['data-test']}>
          {closeCourse && (
            <TextElement appearance="p" font="mainFont">
              {localize('[close course text]')}
            </TextElement>
          )}
          {!closeCourse && (
            <ContentWrapper>
              {isConnectionError && (
                <ReconnectionWrapper>
                  <CircleWrapper className={isOffline ? 'Offline' : 'Online'}>
                    {isOffline ? (
                      <CircleLoader iconSize={20} />
                    ) : (
                      <ReconnectedIcon
                        name="ok"
                        size={SIZES.SMALL}
                        shape={ICON.SHAPES.ROUND_FULL_COLOR}
                        shapeColor={'correctColor'}
                        fontSize={7}
                      />
                    )}
                    <TextElement appearance="span" size={14} font="mainFont">
                      {isOffline
                        ? localize('[trying to reconnect]')
                        : localize('[reconnected successfully]')}
                    </TextElement>
                  </CircleWrapper>
                </ReconnectionWrapper>
              )}

              <Figure>
                <LostConnection />
              </Figure>

              <HeaderElement appearance="h1">{title}</HeaderElement>

              <TextElement appearance="p" font="mainFont">
                {message}
              </TextElement>

              <ButtonsWrapper>
                <Button layout={BUTTON.LAYOUT.SECONDARY} onClick={() => this.closeCourse()}>
                  {localize('[close course]')}
                </Button>
              </ButtonsWrapper>

              {isConnectionError && (
                <NoteWrapper>
                  <Note appearance="span">
                    <b>{localize('[connection note]')} </b>
                    {localize('[note use same course]')}
                  </Note>
                </NoteWrapper>
              )}
            </ContentWrapper>
          )}
        </Container>
      </Fragment>
    );
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    colors: getColors(state),
    fonts: getFonts(state),
    accessibilitySettings: getAccessibilitySettings(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    popupAction: bindActionCreators(popupActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommonOverlay);
