import styled from 'styled-components';
import Icon from 'components/common/Icon';
import media from 'components/mixins/media';
import { fontSizeAdjusted } from 'components/mixins/typography';

export const DescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px;
  background-color: transparent;
  font-family: ${props => props.theme.fonts.mainFont};
  color: ${props => props.theme.colors.textColor};
  height: calc(100vh - 120px);
  height: fit-content;
  max-height: min(780px, 100vh);
  max-width: min(640px, 100vw);

  ${media.small`
    margin: 0;
  `}
`;

export const DescriptionHeading = styled.h1`
  ${fontSizeAdjusted(26)};
  font-weight: bold;
  line-height: 1.62;
  text-align: center;
`;
export const DescriptionItemsContainer = styled.div`
  display: block;
  padding: 40px;
  overflow-y: auto;
  box-shadow: 0 10px 40px 0 rgba(39, 17, 0, 0.1);
  background-color: ${props => props.theme.colors.contentBodyColor};
`;
export const DescriptionItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ItemHead = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  margin-top: 32px;
`;

export const ItemHeadingText = styled.h2`
  padding-left: 16px;
  ${fontSizeAdjusted(20)};

  font-weight: 600;
  line-height: 1.8;
  display: inline;
`;

export const ItemDescription = styled.div`
  padding-top: 16px;
  ${fontSizeAdjusted(16)};
`;
export const ItemIcon = styled(Icon)``;
