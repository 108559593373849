import EventHandler from './eventHandler';

class Configuration {
  eventHandler: any;

  constructor() {
    this.eventHandler = new EventHandler();
  }

  subscribe() {
    this.eventHandler.on();
  }

  unsubscribe() {
    this.eventHandler.off();
  }
}

export default new Configuration();
