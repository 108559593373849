import { deepMerge, isEmptyObject } from 'utils/object';
import resourceLoader from 'core/http/resourceLoader';
import {
  DEFAULT_LANGUAGE_CODE,
  CUSTOM_LANGUAGE_CODE,
  COMPANY_CUSTOM_LANGUAGE_CODE
} from 'constants/common';
import { RTL, RTL_LANGUAGES } from 'constants/language';

// eslint-disable-next-line import/prefer-default-export
export const getTranslations = async function(settings: { [key: string]: any }) {
  const { languages: languageSettings } = settings;
  const languageCode = languageSettings.selected.toLowerCase();

  if (languageCode === CUSTOM_LANGUAGE_CODE) {
    const defaultTranslations = await loadTranslation(DEFAULT_LANGUAGE_CODE);
    setDirAttribute(
      languageSettings.customSelected,
      languageSettings.isCompanyCustomRtl,
      languageSettings.customTranslations
    );
    return deepMerge(defaultTranslations, languageSettings.customTranslations);
  }

  document.documentElement.setAttribute('lang', languageCode);

  setDirAttribute(languageCode);

  return loadTranslation(languageCode);
};

function loadTranslation(code: any) {
  return resourceLoader.getLocalJsonResource(`lang/${code}.json`);
}

function setDirAttribute(languageCode: string, isRtl = false, customTranslations = {}) {
  const isCompanyCustomRtl =
    isRtl &&
    !isEmptyObject(customTranslations) &&
    (!languageCode || languageCode === COMPANY_CUSTOM_LANGUAGE_CODE);

  if (RTL_LANGUAGES.includes(languageCode) || isCompanyCustomRtl) {
    document.documentElement.setAttribute('dir', RTL);
  }
}
