import { STATUS_OK } from 'constants/progressStorage';
import { getQuestionsStructure, getTimeSpent } from 'store/course/selectors';
import {
  isPublishMode,
  isCourse,
  isAnswersFromPreviousAttemptEnabled,
  shouldSubmitAllQuestions,
  getTimerEnabled,
  isQuestionPoolEnabled,
  getQuestionPoolSize,
  isRandomizeAnswersEnabled,
  isNewSubsetOnEveryAttempt
} from 'store/settings/selectors';
import { RootAppState } from 'store/types';
import { shouldSaveCrossDevice } from 'store/user/selectors';
import { compress, decompress } from 'utils/compression';
import { isObject } from 'utils/object';
import isEmpty from 'lodash.isempty';
import clonedeep from 'lodash.clonedeep';
import { hasNonAscii } from 'utils/string';
import ExternalStorage from './externalStorage';
import idMapper from './idMapper';
import LocalStorage from './localStorage';
import { responseMapper, urlMapper } from './progressMapper';
import StorageInterface from './storageInterface';
import { getTimerElapsed, getTimerStatus } from '../../store/timer/selectors';

const defaultCourseProgress = {
  url: null,
  user: null,
  answers: [],
  questionPool: [],
  courseChecksum: null,
  attempts: [],
  finished: false,
  allQuestionsSubmitted: false,
  courseAttemptNumber: 0,
  randomizedOptionsList: [],
  accessibility: {}
};

const defaultChecklistProgress = {
  user: {
    email: '',
    name: '',
    authorizationSkipped: false,
    account: null
  },
  checklistItem: {}
};

class ProgressStorage extends StorageInterface {
  progress: any;

  storage: any;

  localStorage: any;

  isScormMode: any;

  questionShortIds: any;

  questionsIndexToIdSchema: any;

  isQuestionPool: boolean;

  questionPoolSize: number;

  shouldShowPreviousAnswers: boolean;

  isSubmitAllAtOnce: boolean;

  nonAsciiCharsSupported: any;

  timerEnabled: boolean;

  isRandomizeAnswersEnabled: boolean;

  isNewSubsetOnEveryAttempt: boolean;

  constructor() {
    super();
    this.progress = {};
    this.storage = null;
    this.isScormMode = false;
    this.questionShortIds = null;
    this.questionsIndexToIdSchema = null;
    this.isQuestionPool = false;
    this.questionPoolSize = 0;
    this.isSubmitAllAtOnce = false;
    this.shouldShowPreviousAnswers = false;
    this.nonAsciiCharsSupported = true;
    this.timerEnabled = false;
    this.isRandomizeAnswersEnabled = false;
    this.isNewSubsetOnEveryAttempt = false;
  }

  initialize({ state, isScormMode }: any) {
    this.localStorage = this.localStorage || new LocalStorage(state);
    if (isCourse(state)) {
      this.progress = clonedeep(defaultCourseProgress);
      this.questionsIndexToIdSchema = getQuestionsStructure(state);
      this.isScormMode = isScormMode;
      this.isQuestionPool = isQuestionPoolEnabled(state);
      this.questionPoolSize = getQuestionPoolSize(state);
      this.shouldShowPreviousAnswers = isAnswersFromPreviousAttemptEnabled(state);
      this.isSubmitAllAtOnce = shouldSubmitAllQuestions(state);
      this.timerEnabled = getTimerEnabled(state);
      this.isRandomizeAnswersEnabled = isRandomizeAnswersEnabled(state);
      this.isNewSubsetOnEveryAttempt = isNewSubsetOnEveryAttempt(state);

      if (shouldSaveCrossDevice(state) && isPublishMode(state)) {
        this.storage = new ExternalStorage(state);
      } else {
        this.storage = this.localStorage;
      }
    } else {
      this.progress = Object.assign({}, defaultChecklistProgress);
      this.progress.checklistItem = {};
      this.storage = this.localStorage;
    }
  }

  get user() {
    return this.progress.user;
  }

  set user(user: any) {
    delete user.password;
    this.progress.user = user;
  }

  get checklistItem() {
    return this.progress.checklistItem;
  }

  get timeSpent() {
    return this.progress.timeSpent;
  }

  get url() {
    const { url } = this.progress;
    return url ? urlMapper.unMap(url) : url;
  }

  set url(url: any) {
    this.progress.url = url;
  }

  get attemptId() {
    return this.progress.attemptId;
  }

  set attemptId(attemptId: any) {
    this.progress.attemptId = attemptId;
  }

  get questionPool() {
    return this.progress.questionPool;
  }

  set questionPool(questionPool) {
    this.progress.questionPool = questionPool;
  }

  get courseChecksum() {
    return this.progress.courseChecksum;
  }

  set courseChecksum(courseChecksum: number) {
    this.progress.courseChecksum = courseChecksum;
  }

  async mergeAccessibilitySettings(newAccessibilitySettings: any) {
    const { accessibility: oldAccessibilitySettings } = (await this.storage?.getProgress()) ||
      this.progress || { accessibility: {} };
    const merged = { ...oldAccessibilitySettings };
    if (oldAccessibilitySettings && newAccessibilitySettings) {
      const keys = [
        ...Object.keys(newAccessibilitySettings),
        ...Object.keys(oldAccessibilitySettings)
      ];
      keys.forEach(k => {
        if (k) {
          merged[k] =
            this.progress.accessibility[k] ||
            oldAccessibilitySettings[k] ||
            newAccessibilitySettings[k];
        }
      });
    }

    return merged;
  }

  async loadLocalAccessibilitySettings() {
    return (await this.localStorage?.getProgress())?.accessibility || {};
  }

  async restoreAnswers() {
    if (!isEmpty(this.progress?.answers)) {
      return true;
    }
    const progress = await this.storage?.getProgress();
    if (progress?.answers) {
      if (isObject(progress.answers)) {
        progress.answers = this._mapObjectAnswersToArray(progress.answers);
      }
      this.progress = progress;
      return true;
    }
    return false;
  }

  async restoreAccessibilitySettings() {
    let progress = await this.storage?.getProgress();
    progress = progress || (await this.localStorage?.getProgress());
    this.progress.accessibility = progress?.accessibility || this.progress.accessibility;

    return this.progress.accessibility;
  }

  async decompressProgress(progress: any) {
    if (this.isScormMode && this.nonAsciiCharsSupported) {
      return decompress(progress);
    }
    return progress;
  }

  async restoreProgress() {
    let progress = await this.storage?.getProgress();
    progress = await this.decompressProgress(progress);
    if (progress && progress.user && progress.user.email === this.progress.user.email) {
      if (isObject(progress.answers)) {
        progress.answers = this._mapObjectAnswersToArray(progress.answers);
      }
      this.addDefaultValues(progress);

      if (!this.timerEnabled) {
        delete progress.timer;
      }
      this.progress = progress;

      return true;
    }

    return false;
  }

  addDefaultValues(progress: any) {
    if (!progress.questionPool) {
      progress.questionPool = [];
    }

    if (!progress.attempts) {
      progress.attempts = [];
    }

    if (!progress.randomizedOptionsList) {
      progress.randomizedOptionsList = [];
    }
  }

  updateQuestionProgress(question: any) {
    const progressIndex = this.questionsIndexToIdSchema.findIndex((id: any) => id === question.id);
    if (progressIndex === undefined) {
      throw new Error('Question does not exist.');
    }
    this.progress.attempts[progressIndex] = question.attemptNumber || 0;
    this.progress.answers[progressIndex] = responseMapper.map(question);
  }

  updateCourseAttemptProgress(courseAttemptNumber: any) {
    this.progress.courseAttemptNumber = courseAttemptNumber;
  }

  updateCourseChecksum(courseChecksum: any) {
    this.progress.courseChecksum = courseChecksum;
  }

  updateCourseProgress(allQuestionsSubmitted: any) {
    this.progress.allQuestionsSubmitted = allQuestionsSubmitted;
  }

  removeQuestionProgress(questionId: any) {
    const progressIndex = this.questionsIndexToIdSchema.findIndex((id: any) => id === questionId);
    if (progressIndex === undefined) {
      throw new Error('Question does not exist.');
    }

    this.progress.answers.slice(progressIndex, 0);
    this.progress.attempts.slice(progressIndex, 0);
  }

  resetTimerProgress() {
    this.progress.timer = {
      elapsed: 0,
      status: 'RESET'
    };
  }

  updateChecklistProgress(checkItem: { id: string; isChecked: boolean }) {
    this.progress.checklistItem[checkItem.id] = checkItem.isChecked;
  }

  updateAccessibilitySettings(accessibilitySettings: any, state: any) {
    if (accessibilitySettings && this.localStorage) {
      this.progress.accessibility = accessibilitySettings;
      this.localStorage.saveProgress(state);
    }
  }

  courseProgressedRestoreFailed() {
    this.progress.url = null;
  }

  async saveProgress(state: RootAppState) {
    if (this.storage && this.storage.saveProgress) {
      this.progress.timeSpent = getTimeSpent(state);
      if (this.timerEnabled) {
        this.progress.timer = {
          elapsed: getTimerElapsed(state),
          status: getTimerStatus(state)
        };
      }
      const progress =
        this.isScormMode && this.nonAsciiCharsSupported ? compress(this.progress) : this.progress;
      await this.storage.saveProgress(progress, state);
    }
  }

  async checkScormNonAsciiSupport() {
    const restoredProgress = await this.storage?.getProgress();
    if (isEmpty(restoredProgress)) {
      await this.storage.saveProgress(compress(this.progress));
      const progress = await this.storage?.getProgress();
      try {
        decompress(progress);
      } catch (e) {
        this.nonAsciiCharsSupported = false;
      }
      await this.storage.saveProgress(restoredProgress);
    } else {
      this.nonAsciiCharsSupported = hasNonAscii(JSON.stringify(restoredProgress));
    }
  }

  async removeProgress(
    isStartOver: boolean,
    isCoursePassed: boolean,
    urlToNavigate?: string | null
  ) {
    await this.storage.removeProgress(isCoursePassed);
    await this._clearProgress(isStartOver, urlToNavigate);
  }

  async authorizeUser(userData: any) {
    if (this.storage && this.storage.authorizeUser) {
      return this.storage.authorizeUser(userData);
    }

    return null;
  }

  async registerUser(userData: any) {
    if (this.storage && this.storage.registerUser) {
      return this.storage.registerUser(userData);
    }

    return null;
  }

  async resetPassword(email: any) {
    if (this.storage && this.storage.resetPassword) {
      return this.storage.resetPassword(email);
    }

    return STATUS_OK;
  }

  async logout() {
    if (this.storage && this.storage.logout) {
      await this.storage.logout();
    }

    this._clearProgressLogout();
  }

  async sendSecretLink() {
    if (this.storage && this.storage.sendSecretLink) {
      return this.storage.sendSecretLink();
    }

    return STATUS_OK;
  }

  use(storage: any) {
    this.storage = storage;
  }

  get randomizedOptionsList() {
    return this.progress.randomizedOptionsList;
  }

  set randomizedOptionsList(randomizedOptionsList: any) {
    this.progress.randomizedOptionsList = randomizedOptionsList;
  }

  get courseAttemptNumber() {
    return this.progress.courseAttemptNumber;
  }

  set courseAttemptNumber(courseAttemptNumber: any) {
    this.progress.courseAttemptNumber = courseAttemptNumber;
  }

  get allQuestionsSubmitted() {
    return this.progress.allQuestionsSubmitted;
  }

  set allQuestionsSubmitted(allQuestionsSubmitted: any) {
    this.progress.allQuestionsSubmitted = allQuestionsSubmitted;
  }

  get submitOnceAttempt() {
    return this.progress.submitOnceAttempt;
  }

  set submitOnceAttempt(submitOnceAttempt: any) {
    this.progress.submitOnceAttempt = submitOnceAttempt;
  }

  get timer() {
    return this.progress.timer;
  }

  getAnswers(questions: any) {
    const progressAnswers = this.progress.answers;
    const progressAttempts = this.progress.attempts;

    const answers: any[] = [];
    progressAnswers.forEach((answer: any, index: any) => {
      const question = this._getProgressedQuestion({ questions, answer, answerIndex: index });
      const attempt = progressAttempts[index] || 0;

      // isEmpty returns true on integer values. hence checking for 100 as well
      if (isEmpty(answer) && answer !== 100 && attempt === 0) {
        return;
      }

      if (!question) {
        return;
      }

      const processedAnswer = this._unMapProgress(question, answer);
      Object.assign(processedAnswer, { attempt });
      answers.push(processedAnswer);
    });

    return answers;
  }

  _getProgressedQuestion({ questions, answer, answerIndex }: { [key: string]: any }) {
    let questionId: string;
    if (this._hasFullProgressStructure(answer)) {
      questionId = this.isScormMode ? idMapper.unMap(answer.id) : answer.id;
    } else {
      questionId = this.questionsIndexToIdSchema[answerIndex];
    }

    return questions.find(({ id }: { [key: string]: any }) => id === questionId);
  }

  _unMapProgress(question: any, answer: any) {
    let userResponse;
    if (this._hasFullProgressStructure(answer)) {
      const { type, response } = answer;
      userResponse = this.isScormMode ? idMapper.unMapResponse(type, response) : response;
      this._rewriteAnswerToShortenStructure(question, userResponse);
    } else {
      userResponse = responseMapper.unMap(answer, question);
    }

    return {
      id: question.id,
      response: userResponse
    };
  }

  _hasFullProgressStructure(answer: any) {
    return answer && isObject(answer) && answer.id !== undefined;
  }

  _rewriteAnswerToShortenStructure(question: any, oldStructureResponse: any) {
    const entity = Object.assign({}, question);
    entity.response = oldStructureResponse;
    entity.isAnswered = true;

    this.updateQuestionProgress(entity);
  }

  async identifyUser() {
    if (this.storage && this.storage.identifyUser) {
      return this.storage.identifyUser();
    }

    return null;
  }

  async userExists(email: any) {
    if (this.storage && this.storage.userExists) {
      return this.storage.userExists(email);
    }

    return STATUS_OK;
  }

  getSocialNetworkAuthLink(socialNetwork: any) {
    if (this.storage && this.storage.getSocialNetworkAuthLink) {
      return this.storage.getSocialNetworkAuthLink(socialNetwork);
    }

    return '#';
  }

  isAuthenticated() {
    if (this.storage && this.storage.isAuthenticated) {
      return this.storage.isAuthenticated();
    }

    return null;
  }

  getHeaders({ contentType, bearerToken }: any) {
    if (this.storage && this.storage.getHeaders) {
      return this.storage.getHeaders({ contentType, bearerToken });
    }
    return {};
  }

  _clearProgressLogout() {
    this.progress = {};
  }

  async _clearProgress(isStartOver = false, urlToKeep?: string | null) {
    const answersToSave =
      this.shouldShowPreviousAnswers &&
      this.isSubmitAllAtOnce &&
      !this.isNewSubsetOnEveryAttempt &&
      !isStartOver
        ? [...this.progress.answers]
        : [];

    const courseAttempts =
      this.isSubmitAllAtOnce && this.progress.courseAttemptNumber && !isStartOver
        ? this.progress.courseAttemptNumber
        : 0;

    const randomizedOptionsList =
      this.isRandomizeAnswersEnabled &&
      this.progress.randomizedOptionsList?.length > 0 &&
      !isStartOver
        ? this.progress.randomizedOptionsList
        : [];

    const questionPoolToSave =
      this.isQuestionPool &&
      !this.isNewSubsetOnEveryAttempt &&
      this.questionPool?.length > 0 &&
      !isStartOver
        ? [...this.questionPool]
        : [];

    const urlToSave =
      this.shouldShowPreviousAnswers &&
      this.isSubmitAllAtOnce &&
      !this.isNewSubsetOnEveryAttempt &&
      !isStartOver &&
      urlToKeep
        ? urlToKeep
        : null;

    const accessibility = { ...this.progress.accessibility };
    const courseChecksum = !isStartOver ? this.progress.courseChecksum : null;
    this.progress = Object.assign({}, defaultCourseProgress, {
      accessibility,
      url: urlToSave,
      courseChecksum,
      answers: answersToSave,
      questionPool: questionPoolToSave,
      courseAttemptNumber: courseAttempts,
      randomizedOptionsList
    });
    this.localStorage.saveProgress(this.progress);
  }

  getToken() {
    if (this.storage && this.storage.getToken) {
      return this.storage.getToken();
    }
    return '';
  }

  _mapObjectAnswersToArray(originalAnswers: any) {
    const answers: any[] = [];
    Object.keys(originalAnswers).forEach((answerIndex: any) => {
      answers[answerIndex] = originalAnswers[answerIndex];
    });

    return answers;
  }
}

export default new ProgressStorage();
