import { FONT_SIZE_ADJUSTMENT_OPTIONS } from 'constants/accessibility';

export type AccessibilityState = {
  isContrastTheme: boolean;
  isReadableFont: boolean;
  fontSize: FONT_SIZE_ADJUSTMENT_OPTIONS;
  isDyslexiaFont: boolean;
  isSeizureSafe: boolean;
};

export type AccessibilityActionTypes = {
  type: any;
  payload?: any;
};

export enum ActionTypes {
  UPDATE_ACCESSIBILITY_SETTINGS = 'UPDATE_ACCESSIBILITY_SETTINGS',
  RESTORE_ACCESSIBILITY_SETTINGS = 'RESTORE_ACCESSIBILITY_SETTINGS'
}

export type AccessibilityOptionDetails = {
  'data-test': string;
  key: keyof AccessibilityState;
  label: string;
  description: string;
  visible?: boolean;
  fullWidth?: boolean;
  showFontSizeAdjustment?: boolean;
  icon: {
    name: string;
    size: number;
  };
};

export enum FONT_SIZE_ACTION {
  INCREASE = 'increase',
  DECREASE = 'decrease'
}
