import { Comment } from 'store/comments/types';

export const sortComments = (comments: Comment[]): Comment[] => {
  comments.sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime());
  return comments;
};

export const isReviewerComment = (comment: Comment, reviewerEmail: string): boolean =>
  comment.createdBy.email === reviewerEmail;

export const getReviewerCommentIndex = (comments: Comment[], commentId: string): number =>
  comments.findIndex(comment => comment.id === commentId);
