export const POPUP_ID_IMAGE_PREVIEW = 'image-preview';
export const POPUP_ID_CLOSE_COURSE = 'close-course';
export const POPUP_ID_NPS_POPUP = 'nps-popup';
export const POPUP_ID_CONTINUE_LATER = 'continue-later';
export const POPUP_ID_ACCESSIBILITY_DESCRIPTION = 'accessibility-description';
export const POPUP_ID_START_NEW_ATTEMPT = 'start-new-attempt';
export const POPUP_ID_CONTINUE_LATER_IN_SCORM = 'continue-later-in-scorm';
export const POPUP_ID_CLOSE_RESOURCE = 'close-resource-popup';
export const POPUP_ID_START_OVER = 'start-over';
export const POPUP_ID_TIMED_OUT = 'timed-out';
