import { FONT_SIZE_ADJUSTMENT_OPTIONS } from 'constants/accessibility';
import { FONT_SIZE, FONT_SIZE_PAGE, PAGE_NAME } from 'constants/components';
import cloneDeep from 'lodash.clonedeep';
import { AccessibilityState } from 'store/accessibility/types';

export const CONTRAST_COLOR = '#1C304A';
export const CONTRAST_TEXT_COLOR = '#2A2A2A';
export const CONTRAST_WHITE_COLOR = '#FFFFFF';
const contrastFontStyles = ['normal', 'heading1', 'heading2', 'heading3', 'heading4'];

type UtilOptions = {
  accessibilitySettings: AccessibilityState;
};

type AdjustedSize = {
  value: number;
  unit: string;
};

type OpacityOptions = {
  contrastValue?: number;
};

export type FontSizeOptions = {
  page?: FONT_SIZE_PAGE;
  skipUnit?: boolean;
  skipLarge?: boolean;
  unit?: string;
};

export const getThemeUtils = (utilOptions: UtilOptions) => {
  const getPixelSize = (value: number, count: number): AdjustedSize => {
    while (count) {
      value *= 1.1;
      value = Math.round(value);
      count--;
    }

    return { value, unit: 'px' };
  };
  const getFontSize = (
    isAccessible = true,
    value = 12,
    options?: FontSizeOptions
  ): number | string => {
    if (!isAccessible) {
      const unit = options?.skipUnit ? undefined : options?.unit || 'px';

      if (unit) {
        return value.toString() + unit;
      }

      return value;
    }

    const isMobile = window.innerWidth < window.innerHeight;
    const page: FONT_SIZE_PAGE = options?.page || PAGE_NAME.INNER_PAGE;
    const LIMITS = FONT_SIZE[page].CONTENT.MAX;
    const limit = isMobile ? LIMITS.MOBILE : LIMITS.DESKTOP;

    let count = utilOptions.accessibilitySettings?.fontSize || 0;
    if (options?.skipLarge && count >= FONT_SIZE_ADJUSTMENT_OPTIONS.LARGE) {
      count--;
    }
    const { value: newValue, unit } = getPixelSize(value, count);
    let returnVal: any = Math.min(Math.round(newValue), limit);
    if (!options?.skipUnit) {
      returnVal += unit;
    }

    return returnVal;
  };

  return {
    getOpacity: (isAccessible = true, value = 0.6, options?: OpacityOptions): number =>
      isAccessible &&
      utilOptions.accessibilitySettings.isContrastTheme &&
      value < (options?.contrastValue || 0.8)
        ? options?.contrastValue || 0.8
        : value,
    getThemeColors: (originalColors: any): { [key: string]: any } => {
      const colors = cloneDeep(originalColors);
      if (utilOptions.accessibilitySettings?.isContrastTheme) {
        colors.mainColor = CONTRAST_COLOR;
        colors.ctaButtonColor = CONTRAST_COLOR;
        colors.textColor = CONTRAST_TEXT_COLOR;
        colors.coverBgOverlayTextColor = CONTRAST_WHITE_COLOR;
        colors.buttonTextColor = CONTRAST_WHITE_COLOR;
        colors.contentBodyColor = CONTRAST_WHITE_COLOR;
      }

      return colors;
    },
    getThemeFonts: (originalFonts: any): { [key: string]: any } => {
      const fonts = cloneDeep(originalFonts);
      if (utilOptions.accessibilitySettings?.isContrastTheme) {
        contrastFontStyles.forEach(fontStyle => {
          if (fonts.hasOwnProperty(fontStyle)) {
            fonts[fontStyle].color = CONTRAST_TEXT_COLOR;
          }
        });
      }

      return fonts;
    },
    getFontSize
  };
};
