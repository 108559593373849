import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'components/common/Popup';
import { getPopupVisibility } from 'store/popup/selectors';
import {
  getBackground,
  isScormMode,
  isResource,
  isSelectedLanguageRtl
} from 'store/settings/selectors';
import { getEmail, getName, isAuthenticated, isAuthenticatedReviewer } from 'store/user/selectors';
import { getReviewLayout, isReviewEnabled } from 'store/modules/selectors';
import { isCourseAccessLimited } from 'store/course/selectors';
import { isLoaderVisibility } from 'store/loader/selectors';
import { LAYOUT } from 'constants/review';
import { RootAppState } from 'store/types';
import UserMenu from '../../userMenu';
import {
  LayoutContainer,
  BackgroundContainer,
  PageContainer,
  PageContentContainer,
  PageContentChild,
  PageSidebarContainer,
  PageSidebarChild,
  BasicStylesSidebar,
  AccessibilityToast,
  AccessibilityInvertedIcon,
  ToastCloseIcon,
  LayoutWrapper
} from './Layout.styled';
import { Slide, toast } from 'react-toastify';
import { localize } from 'core/localization';
import { withTheme } from 'styled-components';
import { Icon } from 'components/common';

type PageSidebarProps = {
  children: any;
  className?: string;
  props?: any;
};
export const PageSidebar = ({ children, className, ...props }: PageSidebarProps) => (
  <PageSidebarContainer className={className} {...props}>
    <PageSidebarChild>{children}</PageSidebarChild>
  </PageSidebarContainer>
);

type BasicStyledSidebarProps = {
  children?: any;
  className?: string;
  background?: any;
};
export const BasicStyledSidebar = ({
  children,
  className = '',
  ...props
}: BasicStyledSidebarProps) => (
  <BasicStylesSidebar className={className} {...props}>
    <PageSidebarChild>{children}</PageSidebarChild>
  </BasicStylesSidebar>
);

type PageContentProps = {
  children: any;
  className?: string;
  props?: any;
};
export const PageContent = ({ children, ...props }: PageContentProps) => (
  <PageContentContainer {...props}>
    <PageContentChild>{children}</PageContentChild>
  </PageContentContainer>
);

type LayoutProps = {
  children: any;
  background: { [key: string]: any };
  isUserAuthenticated: boolean;
  isAuthenticatedReviewer?: boolean;
  popupExpanded: boolean;
  isAccessLimited: boolean;
  isLoaderVisible?: boolean;
  isScorm: boolean;
  reviewMode: boolean;
  reviewLayout: LAYOUT;
  isResourceType: boolean;
  className?: string;
  hasUnhandledError?: boolean;
  userName?: string;
  userEmail?: string;
  theme: any;
  isRtl: boolean;
};

export class Layout extends Component<LayoutProps, {}> {
  // Make a generic toast component to reuse across AIO
  showAccessibilityToast = () => {
    const { theme, isRtl } = this.props;
    toast.success(localize(`[accessibility mode enabled]`), {
      toastId: localize(`[accessibility mode enabled]`),
      pauseOnFocusLoss: false,
      theme: 'light',
      icon: (
        <>
          <AccessibilityInvertedIcon>
            <Icon
              theme={theme}
              name={`universal-access-person`}
              size={14}
              color={`contentBodyColor`}
            />
          </AccessibilityInvertedIcon>
        </>
      ),
      hideProgressBar: true,
      position: isRtl ? toast.POSITION.BOTTOM_LEFT : toast.POSITION.BOTTOM_RIGHT,
      transition: Slide,
      role: 'alert'
    });
  };

  accessibilityCloseButton = () => (
    <ToastCloseIcon theme={this.props.theme} name={'close'} size={6} color={'textColor'} />
  );

  render() {
    const {
      background,
      children,
      popupExpanded,
      className,
      hasUnhandledError,
      isLoaderVisible,
      isResourceType,
      theme,
      isRtl
    } = this.props;
    const bgHeader = background.header;
    return (
      <>
        <LayoutContainer className={className}>
          <LayoutWrapper>
            {!isLoaderVisible && !isResourceType && (
              <BackgroundContainer background={bgHeader} aria-hidden={popupExpanded} />
            )}
            <PageContainer aria-hidden={popupExpanded}>{children}</PageContainer>
            {this.shouldRenderUserMenu() && !isLoaderVisible && (
              <UserMenu
                ariaHidden={popupExpanded}
                hasUnhandledError={hasUnhandledError || false}
                showAccessibilityToast={this.showAccessibilityToast}
              />
            )}
            {popupExpanded && <Popup />}
          </LayoutWrapper>
        </LayoutContainer>
        <AccessibilityToast theme={theme} closeButton={this.accessibilityCloseButton} rtl={isRtl} />
      </>
    );
  }

  shouldRenderUserMenu() {
    const {
      isAccessLimited,
      isScorm,
      isResourceType,
      reviewMode,
      reviewLayout,
      userName,
      userEmail
    } = this.props;

    return reviewMode
      ? !!userName &&
          !!userEmail &&
          ![LAYOUT.LOGIN_WITH_EMAIL, LAYOUT.REGISTER_NEW_REVIEWER].includes(reviewLayout)
      : (!isAccessLimited && !isResourceType) || isScorm;
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    userName: getName(state),
    userEmail: getEmail(state),
    isAuthenticatedReviewer: isAuthenticatedReviewer(state),
    background: getBackground(state),
    popupExpanded: getPopupVisibility(state),
    isUserAuthenticated: isAuthenticated(state),
    isAccessLimited: isCourseAccessLimited(state),
    isLoaderVisible: isLoaderVisibility(state),
    isScorm: isScormMode(state),
    reviewMode: isReviewEnabled(state),
    reviewLayout: getReviewLayout(state),
    isResourceType: isResource(state),
    isRtl: isSelectedLanguageRtl(state)
  };
}

export default withTheme(connect(mapStateToProps)(Layout));
