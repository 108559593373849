import { getQuestion } from 'store/questions/selectors';
import { isRandomizeAnswersEnabled } from 'store/settings/selectors';
import { NO_SELECTED_ID } from 'constants/common';
import { RootAppState } from '../../types';

export const getUserAnswers = (state: RootAppState, questionId: string) => {
  const question = getQuestion(state, questionId);
  const randomizeAnswersEnabled = isRandomizeAnswersEnabled(state);
  const { dropspots: options, randomizedOptions = [] } = question;
  const dropspots = randomizeAnswersEnabled ? randomizedOptions : options;
  return dropspots.map((dropspot: any) => ({
    id: dropspot.id,
    text: dropspot.text,
    x: dropspot.x,
    y: dropspot.y
  }));
};

export const getBackground = (state: RootAppState, questionId: string) =>
  getQuestion(state, questionId).background;

export const getUserResponse = (state: RootAppState, questionId: string) =>
  getQuestion(state, questionId).response;

export const getScore = (state: RootAppState, question: any) => {
  let originalAnswers: { [key: string]: any }[] = [];
  const userAnswers: { [key: string]: any }[] = [];

  if (question.response.length && !question.response[0].items.length) {
    question.response.forEach((list: any) => {
      if (list.id !== NO_SELECTED_ID) {
        const originalAnswerText = question.dropspots.find((item: any) => item.id === list.id).text;
        originalAnswers.push({
          id: list.id,
          text: originalAnswerText
        });
        const answerId = list.items[0].id;
        const answerText = list.items[0].text;
        userAnswers.push({
          id: answerId,
          text: answerText
        });
      }
    });
  } else {
    originalAnswers = question.dropspots.map((answer: any) => ({
      id: answer.id,
      text: answer.text
    }));
  }

  if (JSON.stringify(originalAnswers) === JSON.stringify(userAnswers)) {
    return 100;
  }

  return 0;
};

export const isAttempted = (question: any) => {
  if (Array.isArray(question.response) && question.response?.length) {
    return question.response
      .filter((list: any) => list.id !== NO_SELECTED_ID)
      .some((list: any) => !!list.items?.length);
  }
  return false;
};
