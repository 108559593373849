import { fontSizeAdjusted } from 'components/mixins/typography';
import styled from 'styled-components';
import { transparentize } from 'utils/color';

type ContainerProps = {
  isTimerStopped: boolean;
};
export const Container = styled.div<ContainerProps>`
  display: flex;
  margin: ${props => (props.isTimerStopped ? '8px 20px 8px 8px' : '8px 20px')};
`;

export const DigitContainer = styled.div`
  display: flex;
  width: ${fontSizeAdjusted(20, { property: 'width' })};
  height: ${fontSizeAdjusted(24, { property: 'height' })};
  border-radius: 4px;
  background-color: ${props => transparentize(props.theme.colors.textColor, 0.1)};
  align-items: center;
  justify-content: center;
`;

export const Digits = styled.span.attrs({
  'data-test': 'digital-timer-value'
})`
  display: flex;
  height: 100%;
  ${fontSizeAdjusted(12)};
  font-weight: 700;
  font-family: ${props => props.theme.fonts.secondaryFont.fontFamily};
  color: ${props => props.theme.colors.textColor};
  align-items: center;
  justify-content: center;
`;

export const Separator = styled.span`
  display: flex;
  height: ${fontSizeAdjusted(24, { property: 'height' })};
  content: ':';
  ${fontSizeAdjusted(12)};
  font-weight: 700;
  font-family: ${props => props.theme.fonts.secondaryFont.fontFamily};
  color: ${props =>
    transparentize(props.theme.colors.textColor, props.theme.themeUtils.getOpacity(true, 0.5))};
  align-items: center;
  justify-content: center;
  margin: 0 4px;
`;
