import pathToRegexp from 'path-to-regexp';
import {
  INDEX_PATH,
  SECTIONS_PATH,
  SECTION_PATH,
  RESULTS_PATH,
  QUESTION_PATH,
  LEARNING_OBJECTIVE_PATH,
  RESOURCE_PATH
} from 'constants/navigation';

export const isIndexView = pathToRegexp(INDEX_PATH);
export const isSectionsView = pathToRegexp(SECTIONS_PATH);
export const isSectionView = pathToRegexp(SECTION_PATH);
export const isResultsView = pathToRegexp(RESULTS_PATH);
export const isQuestionView = pathToRegexp(QUESTION_PATH);
export const isLearningObjectiveView = pathToRegexp(LEARNING_OBJECTIVE_PATH);
export const isResourceView = pathToRegexp(RESOURCE_PATH);

export const isHomeView = (pathname: any) =>
  isIndexView.test(pathname) || isSectionsView.test(pathname);

export const isResource = (pathname: any) => isResourceView.test(pathname);

export const isCourseOverviewView = (pathname: any) =>
  isSectionView.test(pathname) ||
  isQuestionView.test(pathname) ||
  isResultsView.test(pathname) ||
  isLearningObjectiveView.test(pathname);
