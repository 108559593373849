import React from 'react';
import { connect } from 'react-redux';
import { RootAppState } from 'store/types';
import { getColors, isSelectedLanguageRtl } from 'store/settings/selectors';
import CircularProgressbar from 'react-circular-progressbar';
import Icon from 'components/common/Icon';
import TimerProgressProvider from './TimerProgressProvider';
import { Container, ProgressContainer, Progress, Content } from './AnalogTimer.styled';

type AnalogTimerProps = {
  timeElapsed: number;
  isTimerStopped: boolean;
  colors: { [key: string]: any };
  onClick(): void;
  isRtl: boolean;
};

export const AnalogTimer: React.FunctionComponent<AnalogTimerProps> = ({
  timeElapsed,
  isTimerStopped,
  colors,
  onClick,
  isRtl
}: AnalogTimerProps) => {
  return (
    <Container isTimerStopped={isTimerStopped} onClick={onClick}>
      <ProgressContainer>
        <Progress>
          <TimerProgressProvider valueStart={0} valueEnd={timeElapsed}>
            {(value: number) => (
              <CircularProgressbar
                percentage={value}
                strokeWidth={8}
                counterClockwise={isRtl}
                styles={{
                  path: {
                    stroke: colors.mainColor,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s'
                  },
                  trail: { stroke: 'transparent' }
                }}
              />
            )}
          </TimerProgressProvider>
        </Progress>
        <Content>
          <Icon name="timer" size={16} />
        </Content>
      </ProgressContainer>
    </Container>
  );
}

function mapStateToProps(state: RootAppState) {
  return {
    colors: getColors(state),
    isRtl: isSelectedLanguageRtl(state)
  };
}

export default connect(mapStateToProps)(AnalogTimer);
