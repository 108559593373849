import { ActionTypes, DragAndDropActionTypes } from './types';
import { QuestionsState } from '../types';

export default function reducer(state: QuestionsState, action: DragAndDropActionTypes) {
  switch (action.type) {
    case ActionTypes.QUESTION_DRAG_AND_DROP_ANSWERS_ARE_SELECTED:
      return action.payload.answers;
    default:
      return state;
  }
}
