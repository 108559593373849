export default class AppError extends Error {
  constructor(message: string) {
    super(message);

    // fix the extended error prototype chain
    // see https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, new.target.prototype);

    this.name = this.constructor.name;
  }
}

export class CourseUpdatedError extends AppError {}
