import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { usePageVisibility } from 'react-page-visibility';
import { RootAppState } from 'store/types';
import { getTimerLimit, shouldSubmitAllQuestions } from 'store/settings/selectors';
import { getTimerElapsed, getTimerStatus } from 'store/timer/selectors';
import { getCourseStructure, getScore, getTimerProgressKey } from 'store/course/selectors';
import {
  getAffectProgressQuestionsCount,
  getAllSurveyQuestions,
  getUnansweredQuestions,
  isAllQuestionsFailed,
  isAllSurveyQuestionsAnswered
} from 'store/questions/selectors';
import * as courseTimerActions from 'store/timer/actions';
import * as courseQuestionActions from 'store/questions/actions';
import { bindActionCreators, Dispatch } from 'redux';
import { getTimeFromMilliseconds, calculateTimeLeft, calculateTimeElapsed } from 'utils/timer';
import {
  TimerStatus,
  PROGRESS_STORAGE_TIMER_UPDATE_INTERVAL,
  APP_STORE_TIMER_UPDATE_INTERVAL
} from 'constants/timer';
import * as treeOfContentActions from 'store/treeOfContent/actions';
import { isAppError } from 'store/app/selectors';
import { TimerProps } from './types';
import TimerBlock from './TimerBlock';
import useInterval from './useInterval';
import useTimeout from './useTimeout';

export const Timer: React.FunctionComponent<TimerProps> = ({
  timeLimit,
  timerStatus,
  timeElapsed,
  isLowResolution,
  timerActions,
  courseScore,
  hasAllQuestionsFailed,
  timerProgressKey,
  // eslint-disable-next-line no-shadow
  isAllSurveyQuestionsAnswered,
  allSurveyQuestions,
  affectProgressQuestionsCount,
  hasUnhandledError
}: TimerProps) => {
  const isPageVisible = usePageVisibility();
  const [showDigitalTimer, setShowDigitalTimer] = useState(false);
  const onClick = () => setShowDigitalTimer(true);
  const [startedAt, setStartedAt] = useState(0);
  const [updatedAt, setUpdatedAt] = useState(0);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(timeLimit, timeElapsed, 0));
  const [previousTimeElapsed] = useState(timeElapsed);
  const [previousTimerStatus] = useState(timerStatus);
  const isTimerStopped = timerStatus === TimerStatus.STOPPED || hasUnhandledError;
  const isTimerStarted = timerStatus === TimerStatus.STARTED && !hasUnhandledError;

  useInterval(() => {
    if (isTimerStarted) {
      timerActions.setTimerElapsed(timeLimit - timeLeft);
      timerActions.updateTimerProgress();
    }
  }, PROGRESS_STORAGE_TIMER_UPDATE_INTERVAL);

  useInterval(() => {
    if (isTimerStarted) {
      timerActions.setTimerElapsed(timeLimit - timeLeft);
    }
  }, APP_STORE_TIMER_UPDATE_INTERVAL);

  useTimeout(
    () => {
      const currentTimeElapsed = calculateTimeElapsed(startedAt, updatedAt);
      const timeRemaining = calculateTimeLeft(timeLimit, currentTimeElapsed, previousTimeElapsed);
      if (isTimerStarted) {
        if (timeRemaining > 0) {
          const now = Math.ceil(performance.now());
          if (!startedAt) {
            setStartedAt(now);
          }
          setUpdatedAt(now);
          setTimeLeft(timeRemaining);
          localStorage.setItem(timerProgressKey, `${timeLimit - timeRemaining}`);
        } else {
          setTimeLeft(0);
          localStorage.setItem(timerProgressKey, `${timeLimit}`);
        }
      }
    },
    1000,
    timerStatus,
    updatedAt,
    isPageVisible
  );

  useEffect(() => {
    if (isTimerStarted && getTimeFromMilliseconds(timeLeft, 'sec') <= 1) {
      timerActions.setTimerStatus(TimerStatus.TIMED_OUT);
      timerActions.setTimerElapsed(timeLimit);
      setTimeLeft(0);
      timerActions.updateTimerProgress();
    }
  }, [timerStatus, timeLeft, timeLimit, timerActions, isTimerStarted]);

  useEffect(() => {
    if (previousTimerStatus !== TimerStatus.STOPPED && timerStatus === TimerStatus.STOPPED) {
      timerActions.setTimerElapsed(timeLimit - timeLeft);
      timerActions.updateTimerProgress();
    }
  }, [timerStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isTimerStarted && isAllSurveyQuestionsAnswered) {
      if (
        (affectProgressQuestionsCount > 0 && (courseScore === 100 || hasAllQuestionsFailed)) ||
        (affectProgressQuestionsCount === 0 && allSurveyQuestions.length > 0)
      ) {
        timerActions.setTimerStatus(TimerStatus.STOPPED);
        timerActions.updateTimerProgress();
      }
    }
  }, [
    timerStatus,
    isTimerStarted,
    courseScore,
    hasAllQuestionsFailed,
    timerActions,
    isAllSurveyQuestionsAnswered,
    affectProgressQuestionsCount,
    allSurveyQuestions.length
  ]);

  return (
    <TimerBlock
      isTimerStopped={isTimerStopped}
      isLowResolution={isLowResolution}
      showDigitalTimer={showDigitalTimer}
      timeLeft={timeLeft}
      timeLimit={timeLimit}
      onClick={onClick}
    ></TimerBlock>
  );
};

function mapStateToProps(state: RootAppState) {
  return {
    timeLimit: getTimerLimit(state),
    timerStatus: getTimerStatus(state),
    timeElapsed: getTimerElapsed(state),
    submitAllQuestionsEnabled: shouldSubmitAllQuestions(state),
    courseStructure: getCourseStructure(state),
    isLowResolution: state.app.isLowResolution,
    unansweredQuestions: getUnansweredQuestions(state),
    courseScore: getScore(state),
    hasAllQuestionsFailed: isAllQuestionsFailed(state),
    timerProgressKey: getTimerProgressKey(state),
    isAllSurveyQuestionsAnswered: isAllSurveyQuestionsAnswered(state),
    allSurveyQuestions: getAllSurveyQuestions(state),
    affectProgressQuestionsCount: getAffectProgressQuestionsCount(state),
    hasUnhandledError: isAppError(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    timerActions: bindActionCreators(courseTimerActions, dispatch),
    tocActions: bindActionCreators(treeOfContentActions, dispatch),
    questionActions: bindActionCreators(courseQuestionActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Timer);
