import { events } from 'core/events/eventEmitter';
import BaseEventHandler from 'core/events/eventHandler';
import store from 'store';
import { ActionTypes } from 'store/app/types';

export default class EventHandler extends BaseEventHandler {
  handlers: any[];

  constructor() {
    super();
    this.handlers = [
      {
        event: events.APP_LRS_MISCONFIGURATION_ERROR,
        instance: this.appLrsMisconfigurationError.bind(this)
      }
    ];
  }

  appLrsMisconfigurationError() {
    store.dispatch({
      type: ActionTypes.APP_LRS_MISCONFIGURATION_ERROR
    });
  }
}
