import styled from 'styled-components';
import media from 'components/mixins/media';
import { transparentize } from 'utils/color';
import Text from 'components/common/Text';
import Icon from 'components/common/Icon';

import buttonDefaultStyle from 'components/mixins/normalize';
import { fontSizeAdjusted } from 'components/mixins/typography';

type CloseBtnWrapperProps = {
  tabIndex: number;
  ariaLabel: string;
};

export const CloseBtnWrapper = styled.button.attrs((props: CloseBtnWrapperProps) => ({
  tabIndex: props.tabIndex,
  'aria-label': props.ariaLabel
}))<CloseBtnWrapperProps>`
  ${buttonDefaultStyle};
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 100%;
  text-align: center;
  height: 70px;
  width: 70px;
  transition: background-color 0.2s linear;
  cursor: pointer;
  z-index: 2;
  color: #73899e;
  font-family: none;
  &:hover {
    background-color: ${props => transparentize(props.theme.colors.textColor, 0.1)};
    color: ${props => transparentize(props.theme.colors.textColor)};
  }
`;

type CloseBtnIconProps = {
  size: number;
  name: string;
};

export const CloseBtnIcon = styled(Icon)<CloseBtnIconProps>`
  ${fontSizeAdjusted(24)};
`;

export const CloseBtnText = styled(Text)`
  display: block;
  padding-top: 6px;
  ${media.medium`
    display: none;
  `};
  color: #73899e;
`;

export const DocumentContainer = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: white;
  opacity: 0.99;
  z-index: 300;
  padding-bottom: 5%;
  ${media.mobile`
    padding-top: 20%;
  `};
`;

export const Document = styled.div.attrs({
  'data-test': 'pdf-document',
  tabIndex: 0
})`
  height: 100%;
  width: 70%;
  margin-left: 15%;
  margin-top: 3%;
  overflow-y: scroll;
  overflow-x: hidden;

  .canvas-wrapper {
    border: 15px solid;
    border-top: 7.5px solid;
    border-bottom: 7.5px solid;
    border-color: ${props => transparentize(props.theme.colors.textColor)};
    ${media.mobile`
      border: 5px solid;
      border-top: 5px solid;
      border-bottom: 2.5px solid;
  `};
  }
`;
