import React from 'react';
import { withTheme } from 'styled-components';
import { transparentize } from 'utils/color';
import { localize } from 'core/localization';
import { Figure } from './Figure.styled';

type SecretLinkProps = {
  theme: { [key: string]: any };
};
export const SecretLink = (props: SecretLinkProps) => (
  <Figure
    width="260px"
    height="113px"
    viewBox="0 0 260 113"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    arialabel={`${localize('[aria label secret link]')}`}
  >
    <title>Secret link icon</title>
    <desc>Created with sketchtool.</desc>
    <defs>
      <filter id="a" width="100%" height="100%" x="0%" y="0%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceGraphic" />
      </filter>
      <filter id="b" width="100%" height="100%" x="0%" y="0%" filterUnits="objectBoundingBox">
        <feGaussianBlur in="SourceGraphic" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.theme.colors.textColor}
        d="M74.48 18.423H20.984s-9.378 1.012-9.378 8.467c0 7.454 9.378 8.466 9.378 8.466l31.828.06s7.447 1.452 7.447 7.983c0 6.53-7.447 8.466-7.447 
        8.466H36.368s-13.348.815-13.348 8.89c0 8.074 13.348 9.312 13.348 9.312H9.479S0 71.7 0 78.473C0 85.246 9.48 87 9.48 87h207.865s11.703-1.33 
        11.703-8.527c0-7.196-11.703-8.406-11.703-8.406H197.02s-11.868-.707-12.08-8.042c-.211-7.336 12.08-8.709 12.08-8.709l23.586-.816s12.31 1.814 
        12.31-8.376-11.325-8.768-11.325-8.768l28.253.06S260 34.806 260 26.89c0-7.917-10.156-8.89-10.156-8.89l-175.365.423z"
        filter="url(#a)"
        opacity=".02"
      />
      <ellipse
        cx="130"
        cy="109"
        fill={props.theme.colors.textColor}
        filter="url(#b)"
        opacity=".1"
        rx="35"
        ry="4"
      />
      <path
        fill={transparentize(props.theme.colors.mainColor, 0.85)}
        fillRule="nonzero"
        d="M169.97 76.529c0 .997-.333 1.828-.999 2.493-.666.498-1.332.83-2.331.83H93.372c-.833 
        0-1.665-.332-2.331-.83-.666-.665-1-1.496-1-2.493V27.836h79.929V76.53z"
      />
      <path
        fill={props.theme.colors.mainColor}
        fillRule="nonzero"
        d="M168.971 79.022c-.666.498-1.332.83-2.331.83H93.372c-.833 0-1.665-.332-2.331-.83l38.965-33.404 38.965 33.404z"
      />
      <path
        fill={props.theme.colors.mainColor}
        fillRule="nonzero"
        d="M169.97 27.836l-11.323 8.143-26.476 18.946a3.753 3.753 0 0 1-4.496 0l-26.31-18.946-11.323-8.143 
        11.323-8.143 24.644-17.616 1.832-1.33a3.753 3.753 0 0 1 4.496 0l1.832 1.33 11.823 8.476 5.828 4.154 6.827 4.986 11.323 8.143z"
      />
      <g transform="translate(101.286 1.143)">
        <path
          fill={props.theme.colors.contentBodyColor}
          fillRule="nonzero"
          stroke={transparentize(props.theme.colors.textColor, 0.05)}
          strokeOpacity=".2"
          strokeWidth="1.143"
          d="M56.79 11.478L46.637 1.506H3.409A2.765 2.765 0 0 0 .65 4.258v30.286l26.081 18.78a3.185 3.185 0 0 0 3.821-.007L56.79 34.543V11.478z"
        />
        <circle
          cx="28.714"
          cy="29.714"
          r="14.857"
          fill={transparentize(props.theme.colors.mainColor, 0.7)}
          opacity=".8"
        />
        <path
          fill={props.theme.colors.buttonTextColor}
          fillRule="nonzero"
          d="M25.281 28.358l-2.913 2.924a1.922 1.922 0 0 0 0 2.724l2.04 2.04c.366.366.85.566 
          1.368.566.52 0 1.003-.2 1.368-.566l2.901-2.924c.366-.366.566-.85.566-1.368 0-.024 0-.035-.012-.059.024 0 .036.012.06.012.518 0 
          1.002-.2 1.367-.566l2.925-2.925a1.922 1.922 0 0 0 0-2.724l-2.04-2.04a1.956 1.956 0 0 0-1.368-.554c-.52 0-.99.2-1.356.566l-2.925 
          2.913a1.917 1.917 0 0 0-.566 1.368c0 .023 0 .035.012.059-.024 0-.035-.012-.059-.012-.519 0-1.002.2-1.368.566zm2.724-1.238l2.925-2.925a.884.884 0 
          0 1 1.25 0l2.04 2.04a.875.875 0 0 1 0 1.239l-2.937 2.924a.898.898 0 0 1-1.238 0l-.648-.649.59-.59c.2-.2.2-.542 0-.742-.201-.2-.543-.2-.744 
          0l-.59.59-.648-.65a.884.884 0 0 1-.26-.624c0-.236.095-.448.26-.613zm-.743 1.98l.649.65-.59.589c-.2.2-.2.542 0 .743a.509.509 0 0 0 .366.153.49.49 
          0 0 0 .365-.153l.59-.59.648.649a.884.884 0 0 1 0 1.25l-2.924 2.924a.898.898 0 0 1-1.238 0L23.1 33.263a.875.875 0 0 1 0-1.238l2.924-2.924a.884.884 
          0 0 1 .625-.26c.224 0 .448.095.613.26z"
        />
      </g>
      <path
        fill={transparentize(props.theme.colors.textColor, 0.1)}
        fillRule="nonzero"
        d="M148.323 9.722c0 1.496 1.165 2.659 2.664 2.659h7.66L148.323 2.077v7.645z"
      />
    </g>
  </Figure>
);

export default withTheme(SecretLink);
