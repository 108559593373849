import { isAuthorized, isGuestUser } from 'store/user/selectors';
import {
  isResultsTrackingEnabled,
  isNpsEnabled,
  isLrsEnabledForLxp
} from 'store/settings/selectors';
import { RootAppState } from '../types';

// eslint-disable-next-line import/prefer-default-export
export const isXapiInitialized = (state: RootAppState) => state.modules.xapi.isInitialized;

export const shouldInitializeXapi = (state: RootAppState) =>
  isAuthorized(state) &&
  (isResultsTrackingEnabled(state) ||
    isNpsEnabled(state) ||
    (isLrsEnabledForLxp(state) && !isGuestUser(state)));

export const isNpsTrackingOn = (state: RootAppState) => state.modules.xapi.isNpsTrackingOn;

export const isLrsTrackingOn = (state: RootAppState) => state.modules.xapi.isLrsTrackingOn;

export const isLxpTrackingOn = (state: RootAppState) => state.modules.xapi.isLxpTrackingOn;

export const isReviewEnabled = (state: RootAppState) => state.modules.review.isReviewModeEnabled;

export const getReviewApiUrl = (state: RootAppState) => state.modules.review.reviewApiUrl;

export const getReviewLayout = (state: RootAppState) => state.modules.review.layout;

export const isLtiInitialized = (state: RootAppState) => state.modules.lti.isInitialized;
